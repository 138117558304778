import {Component, EventEmitter, inject, Input, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {MediaService} from 'shared/media/media.service';
import {FileData} from 'shared/models';

@Component({
  selector: 'app-audio-upload',
  templateUrl: './audio-upload.component.html',
  styleUrls: ['./audio-upload.component.scss']
})
export class AudioUploadComponent {

    @Input() public audio?: FileData<'audio/mpeg'> = null;
    @Output() public audioChange = new EventEmitter<FileData<'audio/mpeg'>>();
    @Input() public placeholderMediaType? = "audio";

    private mediaService = inject(MediaService);

    public acceptedTypes = this.mediaService.getMimeTypes('audio').join(', ');
    public uploadFile: (file: File) => Observable<FileData<'audio/mpeg'>> = this.mediaService.uploadAudio.bind(this.mediaService);

    public handleFileUploadSuccess(uploadedFile: FileData<'audio/mpeg'>): void {
        this.audioChange.emit(uploadedFile);
        this.audio = uploadedFile;
    }


}
