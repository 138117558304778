import {Component, Input, OnChanges, OnInit, ViewEncapsulation} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';

@Component({
    selector: 'app-activity-editor-text-input',
    templateUrl: './activity-editor-text-input.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ActivityEditorTextInputComponent implements OnInit, OnChanges {
    @Input() public control!: FormControl;
    @Input() public characterLimit: number;
    @Input() public icon: string;
    @Input() public placeholder: string;
    @Input() public isAnAbsoluteLimit = true;

    public availableCharacters: number;

    // Angular lifecycle hooks

    public ngOnInit(): void {
        this.updateControlValidity();
        this.updateCharacterLimitInformations();
    }

    public ngOnChanges(): void {
        this.updateControlValidity();
        this.updateCharacterLimitInformations();
    }

    // Logic

    private updateControlValidity(): void {
        if (this.characterLimit !== undefined && !!this.control) {
            // add validator
            const validators = this.control.validator ? [this.control.validator] : [];
            validators.push(Validators.maxLength(this.characterLimit));
            this.control.setValidators(validators);
        }
    }

    private updateCharacterLimitInformations(): void {
        if (this.characterLimit !== undefined && !!this.control) {
            this.availableCharacters = this.characterLimit - (this.control.value?.length || 0);

            this.control.valueChanges.subscribe((value: string) => {
                this.availableCharacters = this.characterLimit - value.length;

                if (this.isAnAbsoluteLimit && this.availableCharacters < 0) {
                    this.control.setValue(value.slice(0, this.characterLimit));
                }
            });
        }
    }
}
