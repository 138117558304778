import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MediaUploadModule} from '@modules/activity-edition/core/features/media-upload/media-upload.module';
import {
    ActivityEditionPointImgButtonSelectedElementComponent
} from '@modules/activity-edition/core/ui/typologies-form/activity-edition-point-img/activity-edition-point-img-button-selected-element/activity-edition-point-img-button-selected-element.component';
import {
    ActivityEditionPointImgGridComponent
} from '@modules/activity-edition/core/ui/typologies-form/activity-edition-point-img/activity-edition-point-img-grid/activity-edition-point-img-grid.component';
import {
    ActivityEditionPointImgUploadComponent
} from '@modules/activity-edition/core/ui/typologies-form/activity-edition-point-img/activity-edition-point-img-upload/activity-edition-point-img-upload.component';
import {
    ActivityEditionPointImgZoneGeneratorComponent
} from '@modules/activity-edition/core/ui/typologies-form/activity-edition-point-img/activity-edition-point-img-zone-generator/activity-edition-point-img-zone-generator.component';
import {ActivityEditionTrueFalseComponent} from '@modules/activity-edition/core/ui/typologies-form/activity-edition-true-false/activity-edition-true-false.component';
import {ActivityEditionPreviewQcmComponent} from '@modules/activity-edition/core/ui/typologies-preview/activity-edition-preview-qcm/activity-edition-preview-qcm.component';
import {
    ActivityEditionPreviewTrueFalseComponent
} from '@modules/activity-edition/core/ui/typologies-preview/activity-edition-preview-true-false/activity-edition-preview-true-false.component';
import {FuseSharedModule} from 'fuse-core/shared.module';
import {SharedModule} from 'shared/shared.module';
import {
    ActivityEditionTypologyIconComponent
} from './activity-edition-typology-icon/activity-edition-typology-icon.component';
import {
    ActivityEditionTypologyBlocComponent
} from './activity-edition-typology-bloc/activity-edition-typology-bloc.component';
import {ActivityEditionGridComponent} from './activity-edition-grid/activity-edition-grid.component';
import {
    ActivityEditorTextInputComponent
} from '@modules/activity-edition/core/ui/activity-editor-text-input/activity-editor-text-input.component';
import {ActivityEditionRadioButtonComponent} from './activity-edition-radio-button/activity-edition-radio-button.component';
import {ActivityEditionHorizontalSelectorComponent} from './activity-edition-horizontal-selector/activity-edition-horizontal-selector.component';
import { ActivityEditionPreviewTextMatchingComponent } from './typologies-preview/activity-edition-preview-text-matching/activity-edition-preview-text-matching.component';
import { ActivityEditionPreviewSortMatComponent } from './typologies-preview/activity-edition-preview-sort-mat/activity-edition-preview-sort-mat.component';
import { ActivityEditionFlashcardEditComponent } from './activity-edition-flashcard-edit/activity-edition-flashcard-edit.component';


@NgModule({
    declarations: [
        ActivityEditionTypologyIconComponent,
        ActivityEditionTypologyBlocComponent,
        ActivityEditionGridComponent,
        ActivityEditorTextInputComponent,
        ActivityEditionRadioButtonComponent,
        ActivityEditionTrueFalseComponent,
        ActivityEditionPointImgZoneGeneratorComponent,
        ActivityEditionPointImgButtonSelectedElementComponent,
        ActivityEditionPointImgGridComponent,
        ActivityEditionPointImgUploadComponent,
        ActivityEditionHorizontalSelectorComponent,
        ActivityEditionPreviewQcmComponent,
        ActivityEditionPreviewTrueFalseComponent,
        ActivityEditionPreviewTextMatchingComponent,
        ActivityEditionPreviewSortMatComponent,
        ActivityEditionFlashcardEditComponent,
    ],
    exports: [
        ActivityEditionTypologyBlocComponent,
        ActivityEditionGridComponent,
        ActivityEditorTextInputComponent,
        ActivityEditionRadioButtonComponent,
        ActivityEditionTrueFalseComponent,
        ActivityEditionTypologyIconComponent,
        ActivityEditionPointImgZoneGeneratorComponent,
        ActivityEditionPointImgButtonSelectedElementComponent,
        ActivityEditionPointImgUploadComponent,
        ActivityEditionHorizontalSelectorComponent,
        ActivityEditionPreviewQcmComponent,
        ActivityEditionPreviewTrueFalseComponent,
        ActivityEditionPreviewTextMatchingComponent,
        ActivityEditionPreviewSortMatComponent,
        ActivityEditionFlashcardEditComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        FuseSharedModule,
        MediaUploadModule,
    ]
})
export class UiModule {
}
