import {Component, inject, Input, OnChanges, SimpleChanges} from '@angular/core';
import {ActivityContent, ActivityGranule, ActivityMetaEntityAttributes, ActivityReference, AnswersContent} from '@modules/activities/core/models';
import {TypologyLabel} from '@modules/activities/core/typologies/typology.label';
import {TypedDataEntityInterface} from 'shared/models/octopus-connect';
import {ShortAnswerActivityGranule} from '@modules/activities/core/models/activities/typologies/short-answer-activity.granule';
import {TranslateService} from "@ngx-translate/core";
import {get} from "lodash-es";

const DEFAULT_LANGUAGE = 'fr';

const ACTIVITY_NAME_LIMIT = 65;
const ACTIVITY_NAME_ENDING = '...';

const EXTERNAL_ACTIVITY_TRANSLATION_KEY = 'pretty_activity.typology_external_activity_label';

@Component({
    selector: 'app-pretty-activity-card',
    templateUrl: './pretty-activity-card.component.html'
})
export class PrettyActivityCardComponent<T extends ActivityContent, U> implements OnChanges {
    @Input() activity!: ActivityGranule<T, U>;

    private translateService = inject(TranslateService)

    public metadatas: TypedDataEntityInterface<ActivityMetaEntityAttributes>;
    public activityReference: ActivityReference<U, T>;
    public typology: { id: string; label: TypologyLabel };
    public wording: string;
    public instruction: string;
    public activityContent: T;

    ngOnChanges(_changes: SimpleChanges): void {
        this.metadatas = this.activity?.getEmbed<ActivityMetaEntityAttributes>('metadatas') as TypedDataEntityInterface<ActivityMetaEntityAttributes>;
        this.activityReference = this.activity?.get('reference');
        this.typology = this.metadatas?.get('typology');
        this.wording = this.activityReference?.wording;
        this.instruction = this.activityReference?.instruction;
        this.activityContent = this.activityReference?.activity_content;
    }


    public activityName = () => {
        if (this.typology.label === TypologyLabel.external) {
            return this.translateService.instant(EXTERNAL_ACTIVITY_TRANSLATION_KEY);
        }

        const strDescription = [this.wording, this.instruction];
        const raw = strDescription.map(s => (s ?? '').replace(/<[^>]*>/g, ''))
            .join(' ');

        return raw.length > ACTIVITY_NAME_LIMIT ? raw.substring(0, ACTIVITY_NAME_LIMIT - ACTIVITY_NAME_ENDING.length) + ACTIVITY_NAME_ENDING : raw;
    };

    public getUserFriendlyTypologyLabel(
        typology: { id: string; label: TypologyLabel },
        rawActivity: ActivityGranule
    ): string {
        let label: string = typology?.label;

        if (label === TypologyLabel.shortAnswer) {
            const shortAnswerActivity = rawActivity as ShortAnswerActivityGranule;
            if (shortAnswerActivity.attributes.reference.config?.longAnswer) {
                label = 'long_answer';
            }
        }

        return 'pretty_activity.typology_' + label + '_label';
    }

    public isActivityValid(): boolean {
        if (!this.typology) {
            console.error('Error typology with granule : ' + this.activity.id);
            return false;
        }
        if (this.typology?.label === TypologyLabel.fillBlanks) {
            const fillBlanksContent = this.activityContent as AnswersContent;
            const blanksCount = (fillBlanksContent.answer_text.match(/\[\]/g) || []).length;
            if (blanksCount !== fillBlanksContent.answers.length) {
                console.error('Error number of blanks with fillBlank granule : ' + this.activity.id);
                return false;
            }
        }
        return true;
    }

    public getActivitylanguage() {
        const instructionLanguage = get(this.activityReference, 'config.language.instruction');
        const wordingLanguage = get(this.activityReference, 'config.language.wording');
        return instructionLanguage ?? wordingLanguage ?? DEFAULT_LANGUAGE
    }
}
