import {TypologyLabel} from '@modules/activities/core/typologies/typology.label';
import {ActivityContent, AnswersContent} from 'app/@modules/activities/core/models/activities/activity-contents';
import {FlashcardAttribute} from 'shared/flashcard';
import {MergeTypes} from 'shared/merge';
import {TypedDataEntityInterface} from 'shared/models/octopus-connect';
export interface ActivityReference<U, T extends ActivityContent = AnswersContent> {
    wording: string;
    wordingAudio: string;
    activity_content: T;
    instruction: string;
    instructionAudio: string;
    config: U;
    feedback?: string;
    finalFeedback?: string;
    typology?: string; // allez voir dans metadatas pour trouver le l'id de la typologie
    flashcards: ({id: string} & FlashcardAttribute)[];
}

export type ActivityReferenceEntity<U, T extends ActivityContent = AnswersContent> = TypedDataEntityInterface<ActivityReference<U, T>>

export type UpdatableActivityReference<U> =
    Partial<MergeTypes<[
        Omit<ActivityReference<U>, 'activity_content' | 'typology' | 'flashcards'>,
        {activity_content?: number},
        {typology?: number},
        {flashcards: (string | number)[]},
    ]>>;
