import {Injectable} from '@angular/core';
import {Type} from '@modules/activities/core/lessons/services/lessons.service';
import {TypologyLabel} from '@modules/activities/core/typologies/typology.label';
import {ModelSchema, Structures} from 'octopus-model';
import {getSettingByRole, SwitchSettingsType} from 'shared/utils/settings';
import {modulesSettings} from '../../../../../settings';
import {AuthenticationService} from '@modules/authentication';
import {Roles, RolesOrDefault} from 'shared/models/roles';
import {get} from 'lodash-es';
import * as _ from 'lodash-es';

const rawSettings = {
    actionButtonsInMultiMatrix: Structures.object({
        default: []
    }),
    activitiesBroadcastLifeCycle: Structures.boolean(false),
    activityOptionsEditable: Structures.array(['titleAndInstruction']),
    allowedMediaTypeCombination: Structures.array([
        ['image', 'audio'],
        ['image', 'text'],
        ['video', 'text'],
        ['audio', 'text'],
    ]),
    allowedRolesForModelsAssignation: Structures.array([]), // WARN : An assignment of model make the model cannot be deleted or updated
    allowedRolesForModelsCreation: Structures.array(['manager']),
    allowedRolesForAutoAssignmentCreation: Structures.array(['trainer', 'manager', 'administrator']),
    availableAddButtons: Structures.object({
        default: ['activity', 'divider', 'resource']
    }),
    bannerInfoAddUsages: Structures.object(),
    closeButtonPng: Structures.boolean(false),
    cardLayout: Structures.string('card-simple-course'),
    canSelectActivities: Structures.boolean(false),
    displayBtnRetryInSummarySubLesson: Structures.boolean(true),
    displayCreateLessonHelper: Structures.boolean(false),
    displayDetailsBlockInSummarySubLesson: Structures.boolean(true),
    displayFeedbacks: Structures.boolean(false),
    displayFullscreenButton: Structures.boolean(true),
    displayGradeBlockInSummarySubLesson: Structures.boolean(false),
    displayLearnerInfo: Structures.boolean(true),
    displayOptionalLessonRecapButton: Structures.boolean(true),
    displayQuizBtnInCard: Structures.boolean(false),
    displayTipsInListOfActivities: Structures.boolean(false),
    dynamicRewardMilestones: Structures.array([]),
    enableTTS: Structures.boolean(false),
    filtersToClearOnSearchFormChange: Structures.array([]),
    forceUrlByDefaultWhenBackToLessonList: Structures.boolean(false),
    gettingStarted: Structures.object({}),
    grade: Structures.boolean(),
    hideUserActionButtonsWhenDisabled: Structures.boolean(false),
    hideUserActionButtons: Structures.boolean(false),
    hideUserActionButtonsByRole: Structures.array([]),
    idFilterToUseInPeriodListMyLessonButton: Structures.string('2683,2681,4270'),
    imageFullscreenButton: Structures.boolean(),
    initLoadLessonFilter: Structures.array(['author']),
    isActivitiesListMustBeDisplayed: Structures.boolean(false),
    isAddLessonCardNewStyle: Structures.boolean(false),
    isArrowNavigationEnabled: Structures.array([]),
    isInstanceMustCheckLicense: Structures.boolean(false),
    isLessonEditionEnabled: Structures.object({default: false, trainer: true, manager: true, administrator: true}),
    isLessonDuplicationEnabled: Structures.object({default: false, trainer: true, manager: true, administrator: true}),
    isLessonPreviewAccessible: Structures.object({default: false}),
    isListFormatStepper: Structures.boolean(false), // generic stepper use mat-select list or button
    isModalConfirmBeforeCloseExo: Structures.boolean(false),
    isOnlyModelLesson: Structures.boolean(false),
    isOnboardingEnabled: Structures.boolean(false),
    isPercentileDisplayAllowed: Structures.boolean(true),
    isRecapAtEndOfLessonEnabled: Structures.boolean(true),
    isStepperAllowedAllStep: Structures.boolean(false),
    isTtsEnabled: Structures.boolean(true),
    latexKeyboard: Structures.boolean(true),
    lessonStep: Structures.object(),
    lessonMetadataDialogFields: Structures.object({default: []}),
    menuLessonsDeployable: Structures.object({default: []}),
    numberOfTryBeforeDisplayCorrection: Structures.number(null),
    openDialogInfoAfterDuplicateLesson: Structures.object({default: false}),
    openLessonInApp: Structures.boolean(false),
    periodBackGround: Structures.array([{id: '4119', background: 'english--background'}, {id: '4068', background: 'spanish--background'}]),
    progress: Structures.boolean(),
    rolesCanShowBannerInfo: Structures.array(),
    routeOnExitLesson: Structures.array(['lessons', 'list']), // route by default when exit lessons some instance need another one
    searchFields: Structures.object({
        default: {
            default: ['educationnalLevel', 'concepts', 'thematics', 'bookmarks', 'autocorrection', 'title', 'launchSearch', 'countEntities']
        }
    }),
    selectedTabIndex: Structures.number(0),
    shareableModel: Structures.number(0),
    shouldExitIfNoAssignment: Structures.boolean(false),
    shouldSetDefaultOptionsOnActivityList: Structures.boolean(false),
    shouldShowLaunchButton: Structures.boolean(false),
    shouldStartOnFullScreen: Structures.boolean(false),
    showActivityQuestionIndex: Structures.boolean(false),
    showActivityTitle: Structures.boolean(false),
    showBasePlatformPathButton: Structures.boolean(false),
    showGenericProgressBar: Structures.object({default: {default: false}}),
    showHelp: Structures.boolean(false),
    showIconTitleLesson: Structures.boolean(false),
    showLessonTitle: Structures.boolean(true),
    showLessonWithNoSubTheme: Structures.boolean(true),
    showMultiZoneProgressBar: Structures.object({default: {default: false}}),
    showSubLessonTitle: Structures.boolean(false),
    showRewardPageAfterSubLessonEnded: Structures.boolean(false),
    stepTitlePrefixPosition: Structures.boolean(false),
    stepper: Structures.object({
        default: {
            default: {
                align: 'start',
                position: 'left',
                showRecap: true,
                stepValidationNeedSave: true,
            }
        }
    }),
    stepperLoadStatus: Structures.boolean(false),
    useNicknameInsteadOfUsername: Structures.boolean(false),
    useSummaryPageInsteadOfRecap: Structures.boolean(false),
    hideTopBarForSpecificActivitiesTypes: Structures.array([]),
}

const settingsStructure: ModelSchema = new ModelSchema(rawSettings);
type SwitchedType = SwitchSettingsType<typeof rawSettings>;

type OverrideType = {
    actionButtonsInMultiMatrix: { [key in TypologyLabel | 'default']: string[] },
    activityOptionsEditable: string[],
    allowedMediaTypeCombination: string[][],
    allowedRolesForAutoAssignmentCreation: Roles[],
    allowedRolesForModelsAssignation: Roles[],
    allowedRolesForModelsCreation: Roles[],
    availableAddButtons: { [key in RolesOrDefault]: string[] },
    dynamicRewardMilestones: number[],
    hideTopBarForSpecificActivitiesTypes: string[],
    isLessonEditionEnabled: { [key in RolesOrDefault]: (boolean | { [assignmentType: string]: boolean }) },
    isLessonPreviewAccessible: { [key in RolesOrDefault]: boolean },
    lessonMetadataDialogFields: { [key in RolesOrDefault]: string[] },
    lessonStep: {
        typeSteps: (Type & {limit: number, markers: boolean, openMarkerAutomatic: boolean, markersLimit: number})[],
        outsidePollColor: string,
        insidePollColor: string
    },
    menuLessonsDeployable: { [key in RolesOrDefault]: string | unknown[] },
    periodBackGround: {id: string|number, background: string}[],
    rolesCanShowBannerInfo: string[],
    routeOnExitLesson: string[],
    showGenericProgressBar: boolean | { [key in RolesOrDefault]: (boolean | { [assignmentType: string]: boolean }) },
    showMultiZoneProgressBar: boolean | { [key in RolesOrDefault]: (boolean | { [assignmentType: string]: boolean }) },
    stepper: {
        [key in (Roles | 'default')]: {
                align: string,
                position: string,
                showRecap: boolean,
                stepValidationNeedSave: boolean
            }
    },
}

type SettingsType = Omit<SwitchedType, keyof OverrideType> & OverrideType;

@Injectable({
    providedIn: 'root'
})
export class LessonsConfigurationService {
    private privateSettings: Partial<SettingsType>;

    constructor(private authenticationService: AuthenticationService) {
        this.privateSettings = /*this.settings =*/ <Partial<SettingsType>> settingsStructure.filterModel(modulesSettings.activities);
    }

    public get isListFormatStepper(): boolean {
        return this.privateSettings.isListFormatStepper && this.authenticationService.isAtLeastTrainer();
    }

    public shouldStartOnFullscreen(): boolean {
        return this.privateSettings.shouldStartOnFullScreen || false;
    }

    /**
     * Defined if in this instance it must check license in institution
     */
    public isInstanceMustCheckLicense(): boolean {
        return get(this.privateSettings, 'isInstanceMustCheckLicense', false);
    }

    /**
     * if page list of activities must be displayed
     */
    public isActivitiesListMustBeDisplayed(): boolean {
        return get(this.privateSettings, 'isActivitiesListMustBeDisplayed', false);
    }

    public isArrowNavigationEnabled(): string[] {
        return get(this.privateSettings, 'isArrowNavigationEnabled', []);
    }

    public isOnboardingEnabled(): boolean {
        return get(this.privateSettings, 'isOnboardingEnabled', false);
    }

    public getStepperConfig(assignmentType = 'default'): { position: string; align: string; isStepValidationNeedSave: boolean; isRecapActive: boolean } {
        if (this.privateSettings.stepper === null) {
            return null;
        }

        const role = this.authenticationService.accessLevel;
        let targetRole = 'default';
        if (this.privateSettings.stepper?.hasOwnProperty(role)) {
            targetRole = role;
        }

        if (this.privateSettings.stepper[targetRole] === null) {
            return null;
        }

        let assignmentTarget = 'default';
        if (get(this.privateSettings, `stepper.${targetRole}`, {}).hasOwnProperty(assignmentType)) {
            assignmentTarget = assignmentType;
        }

        if (this.privateSettings.stepper[targetRole][assignmentTarget] === null) {
            return null;
        }

        return {
            align: get(this.privateSettings, `stepper.${targetRole}.${assignmentTarget}.align`, 'start'),
            position: get(this.privateSettings, `stepper.${targetRole}.${assignmentTarget}.position`, 'left'),
            isRecapActive: get(this.privateSettings, `stepper.${targetRole}.${assignmentTarget}.showRecap`, true),
            isStepValidationNeedSave: get(this.privateSettings, `stepper.${targetRole}.${assignmentTarget}.stepValidationNeedSave`, true),
        };
    }

    public isMultiZoneProgressBarActive(assignmentType = 'default'): boolean {
        if (this.privateSettings.showMultiZoneProgressBar === false
            || this.privateSettings.showMultiZoneProgressBar === true) {
            return this.privateSettings.showMultiZoneProgressBar;
        }

        const role = this.authenticationService.accessLevel;
        let targetRole = 'default';
        if (this.privateSettings.showMultiZoneProgressBar?.hasOwnProperty(role)) {
            targetRole = role;
        }

        if (this.privateSettings.showMultiZoneProgressBar[targetRole] === true
            || this.privateSettings.showMultiZoneProgressBar[targetRole] === false) {
            return null;
        }

        let assignmentTarget = 'default';
        if (get(this.privateSettings, `showMultiZoneProgressBar.${targetRole}`, {}).hasOwnProperty(assignmentType)) {
            assignmentTarget = assignmentType;
        }

        return get(this.privateSettings, `showMultiZoneProgressBar.${targetRole}.${assignmentTarget}`, false);
    }

    public isGenericProgressBarActive(assignmentType = 'default'): boolean {
        if (this.privateSettings.showGenericProgressBar === false
            || this.privateSettings.showGenericProgressBar === true) {
            return this.privateSettings.showGenericProgressBar;
        }

        const role = this.authenticationService.accessLevel;
        let targetRole = 'default';
        if (this.privateSettings.showGenericProgressBar?.hasOwnProperty(role)) {
            targetRole = role;
        }

        if (this.privateSettings.showGenericProgressBar[targetRole] === true
            || this.privateSettings.showGenericProgressBar[targetRole] === false) {
            return null;
        }

        let assignmentTarget = 'default';
        if (get(this.privateSettings, `showGenericProgressBar.${targetRole}`, {}).hasOwnProperty(assignmentType)) {
            assignmentTarget = assignmentType;
        }

        return get(this.privateSettings, `showGenericProgressBar.${targetRole}.${assignmentTarget}`, false);
    }

    public useSummaryPageInsteadOfRecap(): boolean {
        return get(this.privateSettings, 'useSummaryPageInsteadOfRecap', false);
    }

    public showRewardPageAfterSubLessonEnded(): boolean {
        return get(this.privateSettings, 'showRewardPageAfterSubLessonEnded', false);
    }

    public isRecapAtEndOfLessonEnabled(): boolean {
        return this.privateSettings.isRecapAtEndOfLessonEnabled;
    }

    public isImageFullscreenButtonEnabled(): boolean {
        return get(this.privateSettings, 'imageFullscreenButton', false);
    }

    public isShowSubLessonEnabled(): boolean {
        return get(this.privateSettings, 'showSubLessonTitle', false);
    }

    public isTtsEnabled(): boolean {
        return get(this.privateSettings, 'isTtsEnabled', true);
    }

    public useNicknameInsteadOfUsername(): boolean {
        return _.get(this.privateSettings, `useNicknameInsteadOfUsername`, false);
    }

    public numberOfTryBeforeDisplayCorrection(): number {
        return _.get(this.privateSettings, `numberOfTryBeforeDisplayCorrection`, null);
    }

    /**
     * is the stepper allowed free navigation in assignement mode or just the step after the
     * previous already done
     */
    public isStepperAllowedAllSteps(): boolean {
        return this.privateSettings.isStepperAllowedAllStep || false;
    }

    /**
     * show or not the tips zone in list-of-activities-component
     */
    public isDisplayTipsInListOfActivities(): boolean {
        return this.privateSettings.displayTipsInListOfActivities || false;
    }

    /**
     * start player in app ?
     */
    public openLessonInApp(): boolean {
        return _.get(this.privateSettings, 'openLessonInApp', false);
    }

    /**
     * afficher le clavier latex?
     */
    public displayLatexKeyboard(): boolean {
        return _.get(this.privateSettings, 'latexKeyboard', true);
    }

    /**
     * if true, display header banner info
     */
    public rolesCanShowBannerInfo(): string[] {
        return this.privateSettings.rolesCanShowBannerInfo;
    }

    /**
     * if true, display header banner info
     */
    public bannerInfoMustListenUsages(): { [key: string]: string } {
        return get(this.privateSettings, 'bannerInfoAddUsages', null);
    }


    public canSelectActivities(): boolean {
        return _.get(this.privateSettings, 'canSelectActivities', false);
    }

    /**
     * Récupère les filtres à utiliser pour une route et un rôle d'utilisateur donnés.
     *
     * @param routeKey La clé de la route pour laquelle récupérer les filtres.
     * @param userRole Le rôle de l'utilisateur pour lequel récupérer les filtres.
     * @returns Un tableau de chaînes de caractères contenant les noms des filtres à utiliser.
     */
    getSearchFields(routeKey: string, userRole: string): string[] {
        const searchFields = _.get(this.privateSettings, 'searchFields', {});
        const defaultFilters = searchFields['default'].default || [];
        const routeFilters = routeKey in searchFields ? searchFields[routeKey] : {default: defaultFilters};
        const userFilters = userRole in routeFilters ? routeFilters[userRole] : routeFilters.default;
        return Array.isArray(userFilters) ? [...userFilters] : [];
    }


    /**
     * Récupère pour le role actif la liste des éléments déployables dans le menu lessons
     * Les éléments sont :
     * - soit des id d'éléments (que l'on retrouve dans mappingList),
     * - soit des objets de type FuseNavigationItem ou assimilé
     * @param mappingList liste des FuseNavigationItem ou assimilé identifiables par un id (id: string|number) que l'on trouve dans le setting
     */
    public getDeployableChildrenLessonItems<T extends { id: string | number }>(mappingList?: T[]): T[] {
        const role = this.authenticationService.accessLevel;
        const settingValue = get(this.privateSettings, `menuLessonsDeployable`, {default: []});
        let settingValueForRole = settingValue[role];
        if (settingValueForRole === undefined) {
            settingValueForRole = settingValue.default;
        }

        if (Array.isArray(settingValueForRole) === false) {
            throw new Error(`menuLessonsDeployable setting is not an array or a Role-based array`);
        }

        return settingValueForRole.map((item: string | T) => {
            if (typeof item === 'string') {
                return mappingList.find((mappingItem) => mappingItem.id === item) ?? item;
            }
            return item;
        });
    }

    public isLessonEditionEnabled(): boolean {
        const role = this.authenticationService.accessLevel;
        const settingValue = get(this.privateSettings, `isLessonEditionEnabled`, {default: false});
        const settingValueForRole = settingValue[role];
        return settingValueForRole === undefined ? settingValue.default : settingValueForRole;
    }

    /**
     * if true, display header banner info
     */
    public periodBackGroundToUse(idConcept: string): string {
        const periodBackgroundList = get(this.privateSettings, 'periodBackGround', [{id: '4119', background: 'english--background'}, {
            id: '4068',
            background: 'spanish--background'
        }]);
        const pb = periodBackgroundList.filter((pb: { id: string, background: string }) => pb.id.toString() === idConcept.toString());
        return pb ? pb[0].background : 'english--background';
    }

    /**
     * Si l'on démarre un parcours sans avoir au préalable chargé une assignation, ce setting permet de définir si oui ou non on a le droit de poursuivre (et si non, on doit quitter le parcours). Ce genre de cas arrive quand on recharge un parcours, on perd l'assignation
     */
    public shouldExitIfNoAssignment() {
        return this.privateSettings.shouldExitIfNoAssignment;
    }

    /**
     * liste des id de filtre pour la requete permettant de savoir le nombre d'assignations
     * que l'utilisateur a.
     */
    public getIdFilterToUseInMyLessonButton(): string {
        return get(this.privateSettings, 'idFilterToUseInPeriodListMyLessonButton', '2683,2681,4270');
    }

    public isLessonDuplicationEnabled(): boolean {
        const role = this.authenticationService.accessLevel;
        const settingValue = get(this.privateSettings, `isLessonDuplicationEnabled`, {default: false});
        const settingValueForRole = settingValue[role];
        return settingValueForRole === undefined ? settingValue.default : settingValueForRole;
    }

    public isAddLessonCardNewStyle(): boolean {
        return get(this.privateSettings, 'isAddLessonCardNewStyle', false);
    }

    public shouldShowLaunchButton(): boolean {
        return this.privateSettings.shouldShowLaunchButton;
    }

    public displayQuizBtnInCard(): boolean {
        return this.privateSettings.displayQuizBtnInCard;
    }

    public get displayBtnRetryInSummarySubLesson(): boolean {
        return get(this.privateSettings, 'displayBtnRetryInSummarySubLesson', true);
    }

    public displayGradeBlockInSummarySubLesson(): boolean {
        return get(this.privateSettings, 'displayGradeBlockInSummarySubLesson', false);
    }

    public displayDetailsBlockInSummarySubLesson(): boolean {
        return get(this.privateSettings, 'displayDetailsBlockInSummarySubLesson', false);
    }

    public showBasePlatformPathButton(): boolean {
        return get(this.privateSettings, 'showBasePlatformPathButton', false);
    }
    public hideTopBarForSpecificActivitiesTypes(): string[] {
        return get(this.privateSettings, 'hideTopBarForSpecificActivitiesTypes', []);
    }

    public isTtsActive(): boolean {
        return get(this.privateSettings, 'enableTTS', false);
    }

    public getLessonStep() {
        return this.privateSettings.lessonStep;
    }

    public isLessonPreviewAccessible(accessLevel: Roles) {
        return getSettingByRole(this.privateSettings.isLessonPreviewAccessible, accessLevel);
    }

    public getActionButtonsInMultiMatrix() {
        return this.privateSettings.actionButtonsInMultiMatrix;
    }

    public getUseSummaryPageInsteadOfRecap() {
        return this.privateSettings.useSummaryPageInsteadOfRecap;
    }

    public getActivitiesBroadcastLifeCycle() {
        return this.privateSettings.activitiesBroadcastLifeCycle;
    }

    public getForceUrlByDefaultWhenBackToLessonList() {
        return this.privateSettings.forceUrlByDefaultWhenBackToLessonList;
    }

    public getIsOnlyModelLesson() {
        return this.privateSettings.isOnlyModelLesson;
    }

    public getShouldSetDefaultOptionsOnActivityList() {
        return this.privateSettings.shouldSetDefaultOptionsOnActivityList;
    }

    public getDynamicRewardMilestones() {
        return this.privateSettings.dynamicRewardMilestones;
    }

    public getLessonMetadataDialogFields(role: Roles) {
        return getSettingByRole(this.privateSettings.lessonMetadataDialogFields, role);
    }

    public getAllowedRolesForAutoAssignmentCreation() {
        return this.privateSettings.allowedRolesForAutoAssignmentCreation;
    }

    public getAllowedRolesForModelsCreation() {
        return this.privateSettings.allowedRolesForModelsCreation;
    }

    public getAllowedMediaTypeCombination() {
        return this.privateSettings.allowedMediaTypeCombination;
    }

    public getAvailableAddButtons(accessLevel: Roles) {
        return getSettingByRole(this.privateSettings.availableAddButtons, accessLevel);
    }

    public getDisplayLearnerInfo() {
        return this.privateSettings.displayLearnerInfo;
    }

    public getProgress() {
        return this.privateSettings.progress;
    }

    public getGrade() {
        return this.privateSettings.grade;
    }

    public getAllowedRolesForModelsAssignation() {
        return this.privateSettings.allowedRolesForModelsAssignation;
    }

    public getSelectedTabIndex() {
        return this.privateSettings.selectedTabIndex;
    }

    public getEnableTTS() {
        return this.privateSettings.enableTTS;
    }

    public getIsModalConfirmBeforeCloseExo() {
        return this.privateSettings.isModalConfirmBeforeCloseExo;
    }

    public getStepperLoadStatus() {
        return this.privateSettings.stepperLoadStatus;
    }

    public getDisplayFeedbacks() {
        return this.privateSettings.displayFeedbacks;
    }

    public getShowHelp() {
        return this.privateSettings.showHelp;
    }

    public getCloseButtonPng() {
        return this.privateSettings.closeButtonPng;
    }

    public getDisplayFullscreenButton() {
        return this.privateSettings.displayFullscreenButton;
    }

    public getShowIconTitleLesson() {
        return this.privateSettings.showIconTitleLesson;
    }

    public getShowLessonTitle() {
        return this.privateSettings.showLessonTitle;
    }

    public getShowActivityQuestionIndex() {
        return this.privateSettings.showActivityQuestionIndex;
    }

    public getShowActivityTitle() {
        return this.privateSettings.showActivityTitle;
    }

    public getDisplayOptionalLessonRecapButtons() {
        return this.privateSettings.displayOptionalLessonRecapButton;
    }

    public getShareableModel() {
        return this.privateSettings.shareableModel;
    }

    public getDisplayCreateLessonHelper() {
        return this.privateSettings.displayCreateLessonHelper;
    }

    public getRouteOnExitLesson() {
        return this.privateSettings.routeOnExitLesson;
    }

    public getActivityOptionsEditable() {
        return this.privateSettings.activityOptionsEditable;
    }

    public getShowLessonWithNoSubTheme() {
        return this.privateSettings.showLessonWithNoSubTheme;
    }

    public getCardLayout() {
        return this.privateSettings.cardLayout;
    }

    public getGettingStarted() {
        return this.privateSettings.gettingStarted;
    }

    public getStepTitlePrefixPosition() {
        return this.privateSettings.stepTitlePrefixPosition;
    }

    public getHideUserActionButtonsWhenDisabled() {
        return this.privateSettings.hideUserActionButtonsWhenDisabled;
    }

    public getFiltersToClearOnSearchFormChange(): string[] {
        return this.privateSettings.filtersToClearOnSearchFormChange;
    }
}
