import {inject, Injectable} from '@angular/core';
import {MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig} from '@angular/material/legacy-dialog';
import {AuthenticationService} from '@modules/authentication';
import {UserDataEntity} from '@modules/authentication/core/models/user-data-entity.type';
import {CommunicationCenterService} from '@modules/communication-center';
import {ButtonListDialogComponent, ButtonListDialogData} from 'fuse-core/components/button-list/button-list-dialog/button-list-dialog.component';
import {AssignModalComponent} from 'fuse-core/components/card/assign-modal/assign-modal.component';
import {AssignationModalDataInterface} from 'fuse-core/components/card/assign-modal/assignation-modal-data.interface';
import {CardsConfigurationService} from 'fuse-core/components/card/cards-configuration.service';
import {DataCardInterface} from 'fuse-core/components/card/data-card.interface';
import {DataCardType} from 'fuse-core/components/card/data-card.type';
import {Usage} from 'fuse-core/components/card/lesson-card-breadcrumb/usage.interface';
import {ITypeOfCard} from 'fuse-core/components/card/models/card.models';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {filter, mergeMap, take, tap} from 'rxjs/operators';
import {Roles} from 'shared/models';
import {FlagService} from '../../../app/shared/flag.service';

const COMMUNITY_USAGE_ID = '4055';


@Injectable({
    providedIn: 'root'
})
export class CardsService {

    private cardsConfigurationService = inject(CardsConfigurationService);
    private communicationCenter = inject(CommunicationCenterService);
    private dialog = inject(MatDialog);
    private octopusConnect = inject(OctopusConnectService);
    public flagService = inject(FlagService);
    private authService = inject(AuthenticationService);

    public settingBookmark = new Subject<void>();
    public isAutoAssignmentForced: boolean;
    /** @deprecated le Service est transversal, il peut etre utilisé dans un contexte où il n'y a pas de currentDatacard. Il faut éviter d'utiliser ça et privilégié une datacard passée en parametre de la fonction dont on a besoin */
    public currentDatacard: DataCardInterface<DataEntity>;

    public learners = [];
    public classes = [];
    public workgroups = [];
    private isOneLessonIsLaunchState = false;

    constructor(

    ) {
        this.communicationCenter.getRoom('assignation')
            .getSubject('isAutoAssignmentForced')
            .subscribe((value: boolean) => this.isAutoAssignmentForced = value);
    }

    public downloadDoc(uri: string): void {
        window.open(uri, '_blank');
    }

    /**
     * open pop up to set the number of player
     */
    public openAssignmentWithUserDataFormModal<T extends DataEntity>(entity: T, datacard: DataCardInterface<T>, learners = [], classes = [], workgroups = []): void {
        // TODO - get out of card component, should be passed as parameter

        const config = new MatDialogConfig<AssignationModalDataInterface>();

        config.data = {
            lesson: entity,
            resourceInDataCard: datacard.resource,
            askNumberOfViewers: true,
            askTitle: this.cardsConfigurationService.isLaunchLessonAskTitle(),
            askComment: this.cardsConfigurationService.isLaunchLessonAskComment(),
            commentMaxLength: this.cardsConfigurationService.launchLessonCommentMaxLength(),
            learners,
            classes,
            workgroups,
        };

        const confirmDialogRef = this.dialog.open<AssignModalComponent, AssignationModalDataInterface, {
            comment: string,
            numberOfViewers: number,
            title: string,
            datacard: DataCardInterface<T>,
            groups: string[]
        }>(AssignModalComponent, config);

        confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.launchLessonAutoAssignement(
                    entity,
                    datacard,
                    !!result.numberOfViewers ? result.numberOfViewers : null,
                    !!result.comment ? result.comment : null,
                    !!result.title ? result.title : null,
                    result.groups || []
                );
            }
        });
    }

    public openLessonPage<T extends DataEntity>(dataCard: DataCardInterface<T>, originUrl: string, queryParams ?: {[key:string]: string|number}): void {
        this.currentDatacard = dataCard;
        this.communicationCenter.getRoom('lessons').next('openLesson', {
            id: dataCard.resource.id,
            originUrl,
            queryParams: queryParams
        });
    }

    /**
     * launch lesson and assign lesson to current user
     */
    launchLessonAutoAssignement<T extends DataEntity>(dataEntity: T, datacard?: DataCardInterface<T>, nbrePeople?: number, comment?: string, title?: string, groups?: string[], options?: {[key: string]: any}): void {
        // TODO - get out of card component, should be passed as parameter
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((user: UserDataEntity) => {
                if (datacard?.lastAssignment) {
                    this.communicationCenter
                        .getRoom('assignment')
                        .next('launchAssignment', datacard?.lastAssignment);
                    // run lesson
                    let index = this.currentDatacard.resource.get('reference').findIndex((seance) => +seance.id === +dataEntity.id);
                    if (options?.startOnStepIndex) {
                        index = options.startOnStepIndex;
                    }
                    this.isOneLessonIsLaunchState = false;
                    this.currentDatacard.play(this.currentDatacard.resource, {startOnStepIndex: index !== -1 && !!index ? index : 0});
                } else {
                    this.createUserAssignment(user, dataEntity, datacard, nbrePeople, comment, title, groups, options);
                }
            });
    }

    private createUserAssignment<T extends DataEntity>(user: UserDataEntity, dataEntity: T, dataCard: DataCardInterface<T>, nbrePeople?: number, comment?: string, title?: string, groups?: string[], options?: {[key: string]: any}): void {
        // TODO - get out of card component, should be passed as parameter
        const assignmentData = {
            learners: [
                {
                    id: user.id,
                }
            ],
            assignatedCount: nbrePeople,
            comment,
            title,
            project: null,
            rating_base: 4,
            startDate: Date.now(),
            startTime: null,
            dueDate: null,
            dueTime: null,
            nodeId: dataEntity.id,
            group: [],
            groups: groups ? groups : []
        };


        this.communicationCenter
            .getRoom('assignment')
            .next('createAssignment', {
                assignment: assignmentData, callback: assignment => {
                    // lock lesson
                    if (dataEntity.get('locked') !== '1') {
                        const entity = new DataEntity('granule-lesson', dataEntity.attributes, this.octopusConnect, dataEntity.id);
                        entity.set('locked', true);
                        entity.save();
                    }
                    // launch assignment
                    this.communicationCenter
                        .getRoom('assignment')
                        .next('launchAssignment', assignment);
                    // run lesson
                    let index = this.currentDatacard.resource.get('reference').findIndex((seance) => +seance.id === +dataEntity.id);
                    if (options?.startOnStepIndex) {
                        index = options.startOnStepIndex;
                    }
                    this.isOneLessonIsLaunchState = false;
                    this.currentDatacard.play(this.currentDatacard.resource, {startOnStepIndex: index !== -1 && !!index ? index : 0});
                }
            });
    }

    public showFavoriteButton(): boolean {
        return this.cardsConfigurationService.cardDisplay().showButtonBookmarks && this.cardsConfigurationService.cardDisplay().showButtonBookmarks[this.getLabelToUse()];
    }

    public get displayLessonBreadcrumb(): { usages: boolean, concepts: boolean, chapters: boolean, skills: boolean } {
        return this.cardsConfigurationService.cardDisplay().displayLessonBreadcrumb;
    }

    public get viewLessonPage(): boolean {
        return this.cardsConfigurationService.viewLessonPage();
    }

    get isLessonOrSequence(): boolean {
        return this.typeOfCard?.isSequence || this.typeOfCard?.isLesson;
    }

    public getLabelToUse(): DataCardType {
        if (this.typeOfCard?.isTheme) {
            return 'themes';
        }
        return this.currentDatacard?.resource?.get('format')?.label;
    }

    get typeOfCard(): ITypeOfCard {
        return this._typeOfCard;
    }

    private _typeOfCard: ITypeOfCard = {
        isTheme: false,
        isLesson: false,
        isSequence: false,
        isVideo: false,
        isVideoUrl: false,
        isImage: false,
        isAudio: false,
        isDocument: false,
        isForm: false,
        isShared: false,
        isModel: false,
        isUrl: false
    };

    public setTypeOfCard(): void {
        if (this.currentDatacard?.resource?.type === 'theme_search') {
            this._typeOfCard.isTheme = true;
        }
        this._typeOfCard.isLesson = this.isFormat('lesson');
        this._typeOfCard.isSequence = this.isFormat('sequence');
        try {
            this._typeOfCard.isShared = this.currentDatacard?.resource?.get('shared') === 1;
            this._typeOfCard.isModel = this.currentDatacard?.resource?.get('model') === 1
                || this.currentDatacard?.isContextDefinedItAsModel === true;
        } catch (ex) {
            console.error('cardcomponent214' + ex);
        }
    }

    /***
     * @param format : 'videoUrl' or 'video' or 'image' or 'sequence' or 'lesson' or 'form'
     */
    private isFormat(format: string): boolean {
        return (this.getLabelToUse() === format);
    }

    public get isFavorite(): boolean {
        if (this.typeOfCard?.isTheme) {
            return this.currentDatacard.resource.get('bookmarks-theme') === true;
        } else {
            return this.currentDatacard?.resource.attributes.bookmarks === true;
        }
    }

    public displayField(name: string): boolean {
        return this.cardFieldsSettings.includes(name);
    }

    private get cardFieldsSettings(): string[] {
        const role = this.authService.accessLevel;
        let typeDataCard: DataCardType = this.getLabelToUse();

        if (this._typeOfCard.isLesson) {
            typeDataCard = 'lesson';
        }
        if (this._typeOfCard.isModel) {
            typeDataCard = 'model';
        }
        if (this._typeOfCard.isTheme) {
            typeDataCard = 'theme';
        }
        if (this._typeOfCard.isShared) {
            typeDataCard = 'community';
        }

        if (!this.cardsConfigurationService.cardFields()[role]) {
            return this.cardsConfigurationService.cardFields()['default'][typeDataCard];
        }
        return this.cardsConfigurationService.cardFields()[role][typeDataCard];
    }

    private _bookmarksParams: { endPoint: string, type: string } = {endPoint: 'bookmarks', type: 'node'};

    /***
     * params for calling bookmarks
     */
    get bookmarksParams(): { endPoint: string, type: string } {
        return this._bookmarksParams;
    }

    public bookmark(): void {
        this.flagService.flagEntity(this.currentDatacard.resource, this.bookmarksParams.type, this.bookmarksParams.endPoint)
            .subscribe(() => {
                this.settingBookmark.next();
            });
    }

    public playPreview<T extends DataEntity>(datacard?: DataCardInterface<T>) {
        if (datacard) {
            this.currentDatacard = datacard;
        }
        if (this.shouldSelectSubLesson()) {
            this.openSubLessonSelectionModal()
                .pipe(
                    take(1),
                    filter(subLessonId => !!subLessonId),
                    mergeMap((subLessonId) => this.loadLessonById(subLessonId)),
                    tap(subLesson => {
                        const index = this.currentDatacard.resource.get('reference').findIndex((activity) => +activity.id === +subLesson.id);
                        this.currentDatacard.play(this.currentDatacard.resource, {startOnStepIndex: index !== -1 ? index : 0}, true);
                    })
                )
                .subscribe();
        } else {
            this.currentDatacard.play(this.currentDatacard.resource, null, true);
        }
    }

    public shouldSelectSubLesson(): boolean {
        const isNeeded = this.currentDatacard.resource.get('reference') !== undefined
            && this.currentDatacard.resource.get('reference').every((ref: any) => ref.type === 'lesson');
        let isAllowed = false;

        const role = this.authService.accessLevel;
        if (role in this.cardsConfigurationService.chooseSubLessonFeatureActive()) {
            isAllowed = this.cardsConfigurationService.chooseSubLessonFeatureActive()[role];
        } else if ('default' in this.cardsConfigurationService.chooseSubLessonFeatureActive()) {
            isAllowed = this.cardsConfigurationService.chooseSubLessonFeatureActive()['default'];
        }

        return isNeeded && isAllowed;
    }

    public openSubLessonSelectionModal(): Observable<string | number> {
        return this.dialog.open<ButtonListDialogComponent, ButtonListDialogData>(ButtonListDialogComponent, {
            data: {
                title: this.currentDatacard.resource.get('metadatas').title,
                list: this.currentDatacard.resource.get('reference').map((subLesson: { id: string | number, title: string }) => {
                    return ({label: subLesson.title, value: subLesson.id});
                })
            }
        }).afterClosed();
    }

    public loadLessonById(subLessonId: string | number): Observable<DataEntity> {
        const lesson$ = new ReplaySubject<Observable<DataEntity>>(1);

        this.communicationCenter
            .getRoom('lessons')
            .next('getLesson', {
                lessonId: subLessonId,
                callbackSubject: lesson$
            });

        return lesson$.pipe(
            mergeMap(obs => obs)
        );
    }

    public defaultUsage(usages: Usage[]): string | null {
        if (!usages || usages.length === 0) {
            return null;
        }

        return (usages.length >= 2 && usages.find((usage) => usage.id === COMMUNITY_USAGE_ID)) ? usages.find((usage) => usage.id === COMMUNITY_USAGE_ID)?.id : usages[0].id;
    }

    public get usages(): string | null {
        return this.defaultUsage(this.currentDatacard.resource?.get('usage'));
    }

    public displayMenuLink(key: string, context: 'model' | 'lesson'): boolean {

        if (!this.cardsConfigurationService.menuLinks()) {
            return false;
        }
        if (!this.cardsConfigurationService.menuLinks()[context]) {
            return false;
        }

        const menuLinks = this.cardsConfigurationService.menuLinks()[context];
        const role = this.authService.accessLevel;
        if (role in menuLinks) {
            return menuLinks[role].includes(key);
        } else if ('default' in menuLinks) {
            return menuLinks['default'].includes(key);
        }
        return false;
    }

    /**
     * launch lesson or under menu : settings rules
     * originaly in card component
     * @param dataCard datacard info
     */
    public play<T extends DataEntity>(dataCard: DataCardInterface<T>, options?: {[key: string]: any}): void {
        this.currentDatacard = dataCard;
        if (this.cardsConfigurationService.cardPlayOpenMenu()) {
            // open undermenu before running lesson
        } else if (this.isAutoAssignmentForced) {
            const playAutoAssignment = (subLesson: DataEntity) => {
                if (this.cardsConfigurationService.isLaunchLessonAskModalActive(this.authService.accessLevel as Roles)) {
                    this.openAssignmentWithUserDataFormModalDataCardComponent(subLesson, dataCard);
                } else {
                    this.launchLessonAutoAssignement(subLesson, dataCard, null, null, null,  null, options?.quizEnabled ? {startOnStepIndex: 1} : null);
                }
            };

            if (this.shouldSelectSubLesson()) {
                this.communicationCenter
                    .getRoom('assignment')
                    .getSubject('view').pipe(take(1)).subscribe(comp => {
                    if (comp.name === 'AssignmentByStepsComponent') {
                        this.openAutoAssignmentDialog(dataCard?.resource, null, dataCard);
                    } else {
                        // le code est il identique?
                        this.openSubLessonSelectionModal().pipe(
                            take(1),
                            filter((subLessonId) => !!subLessonId),
                            mergeMap((subLessonId) => this.loadLessonById(subLessonId).pipe(take(1))),
                            tap((subLesson) => {
                                playAutoAssignment(subLesson);
                            })
                        ).subscribe();
                    }
                });
            } else {
                // pour lancer si pas de sous parcours et nouvelle modale????
                this.communicationCenter
                    .getRoom('assignment')
                    .getSubject('view').pipe(take(1)).subscribe(comp => {
                    if (comp.name === 'AssignmentByStepsComponent') {
                        this.openAutoAssignmentDialog(dataCard?.resource, null, dataCard);
                    } else {
                        playAutoAssignment(dataCard.resource);
                    }
                });
            }
        } else {
            // launch lesson
            dataCard.play(dataCard.resource);
        }
    }

    private openAutoAssignmentDialog(lesson, activities = null, datacard = null): void {
        this.communicationCenter
            .getRoom('assignment')
            .getSubject('view').pipe(take(1)).subscribe(comp => {
            const dialogRef = this.dialog.open(comp, {
                data: {
                    origine: 'launchInGroup',
                    nodeId: lesson.id,
                    node: lesson,
                    activities: activities,
                    // sublesson when exist (seances)
                    seances: datacard?.resource.get('reference')
                        .filter(elem => elem.type === 'lesson').length > 0 ? datacard?.resource.get('reference')
                        .filter(elem => elem.type === 'lesson') // que des lessons for sceance activity are not sceance
                        .map((subLesson: { id: string | number, title: string }) => {
                            return ({label: subLesson.title, value: subLesson.id});
                        }) : []
                }
            });

            dialogRef.afterClosed().subscribe((data) => {
                if (data) {
                    // launch old code after new modal by step is close
                    this.loadLessonById(data.sceances.filter(s => s.selected === true).length > 0 ? data.sceances.filter(s => s.selected === true)[0].value : data.lesson).pipe(take(1)).subscribe(dataEntity => {
                        this.launchLessonAutoAssignementDataCardComponent(dataEntity, datacard, data?.numberOfLearners, '', datacard?.resource.get('metadatas')?.title);//, data?.groups);
                    });
                }
            });
        });
    }

    /**
     * launch lesson and assign lesson to current user
     */
    launchLessonAutoAssignementDataCardComponent(dataEntity: DataEntity, dataCard: any, nbrePeople?: number, comment?: string, title?: string): void {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((user: UserDataEntity) => {
                this.createUserAssignmentDataCardComponent(user, dataEntity, dataCard, nbrePeople, comment, title);
            });
    }

    /**
     * prepare data and launch create user assignment in assignation
     * @param user to assign
     * @param dataCard datacard info
     * @param nbrePeople number of people who will see the lesson
     * @param comment
     * @param title if not set, the backend will generate a title
     */
    private createUserAssignmentDataCardComponent<T extends DataEntity>(user: UserDataEntity, dataEntity: T, dataCard: DataCardInterface<T>, nbrePeople?: number, comment?: string, title?: string): void {
        const assignmentData = {
            learners: [
                {
                    id: user.id,
                }
            ],
            assignatedCount: nbrePeople,
            comment,
            title,
            project: null,
            rating_base: 4,
            startDate: Date.now(),
            startTime: null,
            dueDate: null,
            dueTime: null,
            nodeId: dataEntity.id,
            group: []
        };

        this.communicationCenter
            .getRoom('assignment')
            .next('createAssignment', {
                assignment: assignmentData, callback: assignment => {
                    // lock lesson
                    if (dataEntity.get('locked') !== '1') {
                        const entity = new DataEntity('granule-lesson', dataEntity.attributes, this.octopusConnect, dataEntity.id);
                        entity.set('locked', true);
                        entity.save();
                    }
                    // launch assignment
                    this.communicationCenter
                        .getRoom('assignment')
                        .next('launchAssignment', assignment);
                    // run lesson
                    dataCard.play(dataEntity);
                }
            });
    }

    /**
     * open pop up to set the number of player
     */
    public openAssignmentWithUserDataFormModalDataCardComponent<T extends DataEntity>(entity: T, datacard: DataCardInterface<T>): void {
        this.openAssignmentWithUserDataFormModal(entity, datacard, this.learners, this.classes, this.workgroups);
    }


    public set isOneLessonIsAlreadyLaunch(spinnerState: boolean) {
        this.isOneLessonIsLaunchState = spinnerState;
    }

    public get isOneLessonIsAlreadyLaunch(): boolean {
        return this.isOneLessonIsLaunchState;
    }

    public isOkayBylessonPageMenuActions(key: string, context: 'model' | 'lesson') {
        if (!this.cardsConfigurationService.lessonPageMenuActions()) {
            return false;
        }
        if (!this.cardsConfigurationService.lessonPageMenuActions()[context]) {
            return false;
        }

        const lessonPageMenuActions = this.cardsConfigurationService.lessonPageMenuActions()[context];
        const role = this.authService.accessLevel;
        if (role in lessonPageMenuActions) {
            return lessonPageMenuActions[role].includes(key);
        } else if ('default' in lessonPageMenuActions) {
            return lessonPageMenuActions['default'].includes(key);
        }
        return false;
    }
}
