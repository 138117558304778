import {ChangeDetectorRef, Component, inject, ViewChild} from '@angular/core';
import {OptionsComponent} from '@modules/activities/core/shared-components/options/options.component';
import {Observable, of} from 'rxjs';
import {BaseActivityComponent, TIME_DELAY_BEFORE_SAVE, TIME_DISPLAYING_CORRECTION} from '../base-activity.component';
import {AnswerResultInterface} from '@modules/activities/core/models';
import {ItemAnswerStateEnum} from '@modules/activities/core/models/item-answer-state.enum';
import {AnswerInterface} from '@modules/activities/core/models/answer.interface';
import {answerStatusEnum} from '@modules/activities/core/models/answer-status.enum';
import {shuffle} from 'shared/utils/array';
import {UntypedFormControl} from '@angular/forms';
import {cloneDeep} from 'lodash-es';
import {v4 as uuidv4} from 'uuid';
import {QcmActivityGranule} from '@modules/activities/core/models/activities/typologies/qcm-activity.granule';

@Component({
    selector: 'app-qcm',
    templateUrl: './qcm.component.html'
})

export class QcmComponent extends BaseActivityComponent<QcmActivityGranule> {
    @ViewChild(OptionsComponent, {static: true}) optionsComponent: OptionsComponent;

    public buttonState: ItemAnswerStateEnum = ItemAnswerStateEnum.pristine;
    public feedback: string;

    // si this.displaySaveBtn === true, on attends une validation par clic sur un bouton "valider"
    // si this.displaySaveBtn === false, on attends une validation par clic sur une réponse ett donc passage automatique a l'activité suivante
    // this.displaySaveBtn sert aussi à savoir si on utilise des checkbox ou non (TODO: ce qui n'est pas top du tout et à modifier).
    public displaySaveBtn = false;
    public isTwoColumns = false;
    public showSubInstruction: boolean;
    public title: string;
    public type = '';
    public answerStateClass = '';
    public isTTSReading: { id: string, value: boolean };
    public checkboxControlList: { [id: string]: UntypedFormControl } = {};
    private waitUntilCorrectionFinished: boolean;
    public uuid = '';

    private ref = inject(ChangeDetectorRef)

    protected setContentData(activityAttributes): void {
        this.uuid = uuidv4();
        this.waitUntilCorrectionFinished = false;
        this.showSubInstruction = this.activitiesService.settings.showSubInstruction;
        if (activityAttributes.reference.config) {
            this.isTwoColumns = activityAttributes.reference.config.doubleColumn !== 0;
            this.displaySaveBtn = activityAttributes.reference.config.displaySaveBtn === true && !this.displayForSummary;
        }
        this.referenceActivityGranule = activityAttributes.reference;
        try {

            this.type = activityAttributes.reference.config.type ? activityAttributes.reference.config.type : '';
        } catch (ex) {
        }
        this.instruction = this.referenceActivityGranule.instruction;
        this.instructionAudio = this.referenceActivityGranule.instructionAudio;
        this.wording = (this.preview && !this.activitiesService.settings['hiddenFieldActivityPreview'].find(field => field === 'wording'))
        || !this.preview ? this.referenceActivityGranule.wording : '';
        this.wordingAudio = (this.preview && !this.activitiesService.settings['hiddenFieldActivityPreview'].find(field => field === 'wordingAudio'))
        || !this.preview ? this.referenceActivityGranule.wordingAudio : '';
        this.setDefaultAnswersAvailable();
        this.answerStateClass = '';
        if (!this.isActivityEmbedded) {
            this.loadUserSave();
        }
        if (this.displayForSummary) {
            this.seeAnswerSolution();
        }
    }

    /**
     * permet d'initialisé le tableau des réponses selectionnés
     * @param answers
     * @param resetSelectEd reset answer selected to false
     * @private
     */
    private setDefaultAnswersAvailable(): void {
        this.availableAnswers = shuffle(this.referenceActivityGranule.activity_content.answers.map((answer) => {
            this.checkboxControlList[answer.id] = new UntypedFormControl(this.answersSelected.some((item) => item.id === answer.id));
            return {
                id: answer.id,
                answer: answer.answer,
                image: answer.image,
                select: !!+answer.select,
                correct_answer: !!+answer.correct_answer,
                state: ItemAnswerStateEnum.pristine,
                feedback: answer.feedback ? answer.feedback : '',
                fid: answer.fid,
                flashcards: answer.flashcards,
                audio: answer.audio ? answer.audio : null,
            };
        }), true);
    }

    protected reset(resetAllSubscribe = false, type = null): Observable<boolean> {
        this.waitUntilCorrectionFinished = false;
        if (this.displayForSummary && this.referenceActivityGranule) {
            this.seeAnswerSolution();
        }
        this.buttonState = null;
        return super.reset(resetAllSubscribe, type);
    }

    protected setAnswer(): void {
        if (this.userSave?.get('userActivity')?.entitySave?.answers && !this.displayForSummary) {
            const answers = this.userSave.get('userActivity').entitySave.answers;
            this.availableAnswers.forEach((option: AnswerInterface, index: number) => {
                if (answers[index]) {
                    this.answersSelected.push(answers[index]);
                }
            });
            if (!this.lessonsService.isTrainerSeeCorrection()) {
                this.checkAnswer();
                this.activitiesService.userAnswer.next(answers);
                this.testAnswer = true;
            }
        }
    }

    private isAtLeastOneMissingAnswer(): boolean {
        const correctAnswers = this.availableAnswers.filter((availableAnswer) => !!availableAnswer.correct_answer);
        const correctAnswersSelected = this.answersSelected.filter((answerSelected) => !!answerSelected.correct_answer);

        return correctAnswers.length > correctAnswersSelected.length;
    }

    protected checkAnswer(): void {
        this.answerStatus = answerStatusEnum.missing;
        const answerResult: AnswerResultInterface = {
            id: +this.activity.id,
            state: ItemAnswerStateEnum.missing,
            isLast: undefined
        };
        /**
         * isAtLeastOneCorrectAnswer: au moins une bonne réponse selectionné
         * isAtLeastOneWrongAnswer: au moins une mauvaise réponse selectionné
         * isAtLeastOneMissingAnswer: au moins une bonne réponse  MANQUANTE
         */
        const isAtLeastOneCorrectAnswer = this.answersSelected.some((answer) => answer.correct_answer);
        const isAtLeastOneWrongAnswer = this.answersSelected.some((answer) => !answer.correct_answer);
        const isAtLeastOneMissingAnswer = this.isAtLeastOneMissingAnswer();
        if (isAtLeastOneCorrectAnswer && isAtLeastOneWrongAnswer) {
            this.feedback = 'activities.feedback.qcm.warning';
            this.answerStatus = answerStatusEnum.wrong;
            answerResult.state = ItemAnswerStateEnum.incorrect;
            this.buttonState = ItemAnswerStateEnum.incorrect;
        }
        if (isAtLeastOneCorrectAnswer && isAtLeastOneMissingAnswer && !isAtLeastOneWrongAnswer) {
            this.feedback = 'activities.feedback.qcm.missing';
            this.answerStatus = answerStatusEnum.missing;
            answerResult.state = ItemAnswerStateEnum.missing;
            this.buttonState = ItemAnswerStateEnum.missing;
        }
        if (isAtLeastOneWrongAnswer && !isAtLeastOneCorrectAnswer) {
            this.feedback = 'activities.feedback.qcm.correction';
            this.answerStatus = answerStatusEnum.wrong;
            answerResult.state = ItemAnswerStateEnum.incorrect;
            this.buttonState = ItemAnswerStateEnum.incorrect;
        }
        // toutes les réponses sont correctes
        if (!isAtLeastOneMissingAnswer && !isAtLeastOneWrongAnswer) {
            this.buttonState = ItemAnswerStateEnum.wasCorrect;
            answerResult.state = ItemAnswerStateEnum.wasCorrect;
            answerResult.isLast = true;
            this.answerStateClass = 'correct';
            this.answerStatus = answerStatusEnum.correct;
        } else {
            this.answerStateClass = 'not-correct';
        }
        if (this.autoCorrection) {
            this.waitUntilCorrectionFinished = true;
            super.manageProgressBarEventToSend(answerResult);
            this.checkAnswersForAutoCorrection();
        }

        this.activitiesService.isUserAnswerStatus
            .next({status: this.answerStatus, index: this.activityStepIndex});
    }

    private checkAnswersForAutoCorrection(): void {
        this.answersSelected.forEach((option) => {
            if (option.correct_answer) {
                option.state = ItemAnswerStateEnum.currentlyCorrect;
            } else {
                option.state = ItemAnswerStateEnum.incorrect;
            }
        });
        // TODO faire une meilleure animation angular
        setTimeout(() => {
            if (this.buttonState === ItemAnswerStateEnum.incorrect) {
                this.buttonState = ItemAnswerStateEnum.pristine;
            }
            if (this.buttonState === ItemAnswerStateEnum.currentlyCorrect) {
                this.buttonState = ItemAnswerStateEnum.wasCorrect;
            }
            this.answersSelected.filter(a => a.state === ItemAnswerStateEnum.incorrect).forEach(a => a.state = ItemAnswerStateEnum.pristine);
            this.answersSelected.filter(a => a.state === ItemAnswerStateEnum.currentlyCorrect).forEach(a => a.state = ItemAnswerStateEnum.wasCorrect);
            if (!this.allAnswerCorrect) {
                this.answersSelected.filter(a => a.state === ItemAnswerStateEnum.wasCorrect).forEach(a => a.state = ItemAnswerStateEnum.pristine);
            }
            this.answerStateClass = this.answerStateClass === 'not-correct' ? '' : 'correct';
            if (this.buttonState === ItemAnswerStateEnum.wasCorrect && this.autoCorrection) {
                if (this.isActivityEmbedded) {
                    super.setFeedBackFromApi(null, answerStatusEnum.correct);
                    this.isActivityEmbeddedDone = true;
                } else {
                    setTimeout(() => {
                        this.doAction('next', ['save']);
                    }, TIME_DELAY_BEFORE_SAVE);
                }
            } else {
                super.setFeedBackFromApi(null, answerStatusEnum.wrong);
                this.waitUntilCorrectionFinished = false;
            }
        }, TIME_DISPLAYING_CORRECTION);
    }

    private get allAnswerCorrect(): boolean {
        const correctAnswers = this.availableAnswers.filter((answer) => !!+answer.correct_answer);
        return !this.answersSelected.some((option: AnswerInterface) => !+option.correct_answer) && correctAnswers.length === this.answersSelected.length;
    }

    protected getGrade() {
        let oldGrade = 0;
        let grade = 0;
        if (this.userSave) {
            const answer = this.userSave.get('userActivity').entitySave.answers[0];

            if (answer && !!answer.correct_answer) {
                oldGrade += 1;
            }
        }
        for (const option of this.answersSelected) {
            if (!!option.correct_answer) {
                grade += 1;
            }
        }
        return {
            newGrade: grade,
            oldGrade: oldGrade
        };
    }

    /**
     * in regard of if it's a standart qcm or a text selection exo class will be added to overide default css
     */
    public parentClass(): string {
        let classToAdd = '';
        const answersCounter: number = this.availableAnswers.length;
        const answersCounterClass = ' answers-counting-' + answersCounter;
        classToAdd = this.testAnswer || this.displaySolution || this.answerSaved ? 'showFinalAnswers' : '';
        classToAdd = classToAdd + this.testAnswer ? ' answersChecked' : '';

        if (this.type === 'isWordSelecting' || this.type === 'isLetterSelecting') {
            return classToAdd + ' is-word-or-lettersSelecting ' + this.answerStateClass + answersCounterClass;
        } else {
            return classToAdd + answersCounterClass;
        }
    }

    public validate(): void {
        if (!this.waitUntilCorrectionFinished) {
            if (this.answersSelected.length) {
                this.checkAnswer();
            }
        }
    }

    public optionChange(): void {
        if (this.answersSelected.length) {
            this.onOptionChange(true);
        }
    }

    public toggleCheck(event, option: AnswerInterface, triggerChange = true): void {
        if (event?.stopImmediatePropagation) {
            event.stopImmediatePropagation();
        }
        const answer = cloneDeep(option);
        if (triggerChange) {
            if (!this.answersSelected.find((answerSelected) => answerSelected.id === option.id)) {
                this.answersSelected.push(answer);
            } else {
                this.answersSelected = this.answersSelected.filter((answerSelected) => answerSelected.id !== option.id);
            }
            this.optionChange();
        }
    }

    /**
     * method is used for checkbox and for button (keep this boil off plate because it's legacy code)
     * need for validate button to disable if no answer was done => isCheckBox = false for this case
     * @param isCheckBox is button or one of the answers
     */
    public isDisabledOptions(isCheckBox = true): boolean {
        // no answer yet nothing to control button must be disabled
        if (!isCheckBox && !this.answersSelected.length || (this.isActivityEmbedded && this.isActivityEmbeddedDone)) {
            return true;
        }
        if (this.displaySaveBtn && (this.buttonState !== null && ItemAnswerStateEnum.wasCorrect !== null)) {
            return this.buttonState === ItemAnswerStateEnum.wasCorrect;
        } else {
            return this.displaySolution || // solution displayed
                this.testAnswer || // answer tested
                (this.answerSaved && this.lessonsService.isLessonEvaluation()) || // answer saved and assignment is assessment or homework
                // this.lessonsService.isAtLeastTrainer() || // check if user is trainer
                this.lessonsService.isLessonCorrected(); // assignment exist and due date finished
        }
    }

    /**
     * sert aux css pour afficher l'état d'une reponse
     * TODO: on doit faire en sorte que les class css utilise le "state" d'une réponse cf: ItemAnswerInterface
     * pour permettre de retourner simplement le "state" qui sera la également la class css
     * @param option
     */
    public optionState(answer: AnswerInterface): string {
        const answerSelected = this.answersSelected?.find((item: AnswerInterface) => item.id === answer.id);
        if (answerSelected) {
            if (answerSelected.state === ItemAnswerStateEnum.pristine) {
                return 'selected';
            }
            return answerSelected.state;
        }
        return ItemAnswerStateEnum.pristine;
    }

    /**
     * create answer entered by the user.
     * no need to create answer because answer already exist.
     * method needed for save in baseActivityComponent
     * @protected
     */
    protected saveAnswer(): Observable<number[]> {
        return of(null);
    }

    protected reviewAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected seeAnswerSolution(): void {
        this.answersSelected = this.referenceActivityGranule.activity_content.answers
            .filter((answer) => !!+answer.correct_answer)
            .map((answer) => {
                return {
                    id: answer.id,
                    answer: answer.answer,
                    image: answer.image,
                    select: !!+answer.correct_answer,
                    correct_answer: !!+answer.correct_answer,
                    state: ItemAnswerStateEnum.wasCorrect,
                    feedback: answer.feedback ? answer.feedback : '',
                    audio: answer.audio ? answer.audio : null,
                    fid: answer.fid,
                    flashcards: answer.flashcards,
                };
            });
        this.displaySolution = true;
    }

    /**
     * set if Text to Speach is currently reading or not
     * @param evt
     */
    public ttsReadingEvent(evt: { id: string, value: boolean }): void {
        this.isTTSReading = evt;
        this.ref.detectChanges();
    }

    public isAnswerSelected(option: AnswerInterface): boolean {
        return !!this.answersSelected.find(answer => answer.id === option.id);
    }

    public get isWrapperForCheckbox(): boolean {
        // faut implementer un activities configuration et y importer les settings
        return this.activitiesService.settings.isWrapperForCheckbox;
    }

    protected getAttempts(): number {
        throw new Error('Method not implemented.');
    }

    /**
     * know if answer contain IMG, (used to display answer img)
     */
    public get answerContainImg(): boolean {
        const regexp = /<img/gi;
        return this.availableAnswers.some((answer) =>
            (!!answer.image && answer.image !== '') || !!answer.answer && answer.answer.search(regexp) !== -1);
    }

    public isImageFullscreenButtonEnabled() {
        return this.lessonsConfigurationService.isImageFullscreenButtonEnabled()
    }

    public areAnswersContainFlashcard(): boolean {
        return this.availableAnswers.some((answer) => answer.flashcards.length > 0 && answer.flashcards.every((flashcard) => !!flashcard.id));
    }

    public areAnswersContainAudio(): boolean {
        return this.availableAnswers.some((answer) => !!answer.audio);
    }

    public playAudioAndToggleCheck($event: MouseEvent, audioId: string, option: AnswerInterface): void {
        this.stopAllAudio();

        const isSelected = this.isAnswerSelected(option);

        if (!isSelected) {
            this.playAudio(audioId);
        }

        this.toggleCheck($event, option);
    }

    private playAudio(audioId: string): void {

        const audio = document.getElementById(audioId) as HTMLAudioElement;
        if (audio) {
            audio.play();
        }
    }

    private stopAllAudio(): void {
        // stop all audio
        const audios = document.getElementsByTagName('audio');
        for (let i = 0; i < audios.length; i++) {
            audios[i].pause();
            audios[i].currentTime = 0;
        }
    }
}
