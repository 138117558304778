// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `mat-accordion mat-expansion-panel {
  box-shadow: initial;
}
mat-accordion mat-expansion-panel mat-expansion-panel-header, mat-accordion mat-expansion-panel mat-expansion-panel-header:hover, mat-accordion mat-expansion-panel mat-expansion-panel-header:not([aria-disabled=true]):hover {
  background: #80b5d3;
}

::ng-deep .mat-expansion-indicator:after {
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/@fuse/components/basic-page/basic-page/basic-page.component.scss"],"names":[],"mappings":"AAGI;EACI,mBAAA;AAFR;AAIQ;EACI,mBAAA;AAFZ;;AAOA;EACI,YAAA;AAJJ","sourcesContent":["@import '@fuse/scss/fuse';\r\n\r\nmat-accordion {\r\n    mat-expansion-panel {\r\n        box-shadow: initial;\r\n\r\n        mat-expansion-panel-header, mat-expansion-panel-header:hover, mat-expansion-panel-header:not([aria-disabled='true']):hover {\r\n            background: map_get($mat-fusedark, 200);\r\n        }\r\n    }\r\n}\r\n\r\n::ng-deep .mat-expansion-indicator:after{\r\n    color:white;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
