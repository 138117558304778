import {Component, Inject, inject, Input, OnInit, HostBinding} from '@angular/core';
import {DataEntity} from 'octopus-connect';
import {ReviewChoices, UserReviewService} from 'fuse-core/components/user-review/user-review.service';
import {AuthenticationService} from '@modules/authentication';
import {FormBuilder} from '@angular/forms';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {animate, style, transition, trigger} from '@angular/animations';

@Component({
    selector: 'app-user-review',
    templateUrl: './user-review.component.html',
    styleUrls: ['./user-review.component.scss'],
    animations: [
        trigger('fadeAndSlideInAndOut', [
            transition(':enter', [
                style({ opacity: 0, transform: 'translateY(100%)' }),
                animate('600ms ease-in-out', style({ opacity: 1, transform: 'translateY(0)' })),
            ]),
            transition(':leave', [
                style({ opacity: 1, transform: 'translateY(0)' }),
                animate('600ms ease-in-out', style({ opacity: 0, transform: 'translateY(100%)' })),
            ]),
        ]),
    ],
})

export class UserReviewComponent implements OnInit {
    private userReviewService = inject(UserReviewService);
    private authService = inject(AuthenticationService);
    public uid: number;
    public type: string;
    public itemToReview: DataEntity;
    reviewChoice: ReviewChoices;

    @HostBinding('@fadeAndSlideInAndOut') animate = true; // Apply entry and leave animations on host element

    constructor(
        private formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) data,
        private dialogRef: MatDialogRef<UserReviewComponent>,
        //@Inject(MAT_BOTTOM_SHEET_DATA) data2,
        private _bottomSheetRef: MatBottomSheetRef<UserReviewComponent>,
    ) {
        this.uid = data.uid;
        //this.type = data.type ? data.type : data2.type;
        this.type = data.type;
        this.itemToReview = data.itemToReview;
    }
    ngOnInit(): void {
    }

    selectReviewChoice(value: ReviewChoices): void {
        if (value !== this.reviewChoice && this.itemToReview) {
            const reviewToSave = {
                nid: this.itemToReview.id.toString(),
                uid: this.authService.loggedUser.id.toString(),
                value: value
            }
            this.userReviewService.saveUserReview(reviewToSave).subscribe();
            // close dialog or bottom after save
            if (this.type === 'dialog') {
                this.dialogRef.close('Form submitted');
            } else {
                this._bottomSheetRef.dismiss('Form submitted');
            }
        }
        this.reviewChoice = value;
    }

    public isUserAtLeastTrainer(): boolean {
        return this.authService.isAtLeastTrainer();
    }

    public notNowAction() {
        // set displayNps to 'update' to process on the backoffice side
        this.authService.loggedUser.set('displayUserReview', 'update');
        this.authService.loggedUser.save();

        // close dialog or bottom after 'not now' button click
        if (this.type === 'dialog') {
            this.dialogRef.close('Not now button click');
        } else {
            this._bottomSheetRef.dismiss('Not now button click');
        }
    }

    protected readonly ReviewChoices = ReviewChoices;
}