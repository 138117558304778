import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AudioUploadComponent} from '@modules/activity-edition/core/features/media-upload/audio-upload/audio-upload.component';
import {ImageUploadComponent} from '@modules/activity-edition/core/features/media-upload/image-upload/image-upload.component';
import {MediaUploadComponent} from '@modules/activity-edition/core/features/media-upload/media-upload.component';
import {VideoUploadComponent} from '@modules/activity-edition/core/features/media-upload/video-upload/video-upload.component';
import {SharedModule} from 'shared/shared.module';


@NgModule({
    declarations: [
        ImageUploadComponent,
        VideoUploadComponent,
        AudioUploadComponent,
        MediaUploadComponent,
    ],
    exports: [
        ImageUploadComponent,
        VideoUploadComponent,
        AudioUploadComponent
    ],
    imports: [
        CommonModule,
        SharedModule
    ]
})
export class MediaUploadModule {
}
