// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.

// The list of file replacements can be found in `angular.json`.
import {GAdsTrackingID, GTMTrackingID, MetaPixelTrackingID, SnapchatTrackingID} from '../app/settings';

export function buildApiUrl(brand?: string): string {
    return 'https://api.humanum.tralalere.com/';
}

export function buildUrl(brand?: string): string {
    return 'https://lms.ilove.tralalere.com/';
}

export function buildNodeUrl(brand?: string): string {
    return 'https://preprod.node.lms.tralalere.com/';
}

export function buildAppUrl(brand?: string): string {
    return `https://app.ilove.tralalere.com/`;
}

export function lrsAuthToken(): string {
    return `ODM2MDIyNGQ0OWVlYmRlYjcwNmFjYjBiYzlmOTczMzI1ZjY4NWM1YjpjZjJhOWE2MWFkMmU4ZDBhODI5ODI3MzEwMDM0MmFkODE4ZTg1YWRi`;
}

export const environment = {
    production: false,
    hmr: false,
    baseApiUrl: buildApiUrl,
    baseNodeUrl: buildNodeUrl,
    baseUrl: buildUrl,
    baseAppUrl: buildAppUrl,
    mindmapUrl: buildApiUrl() + '/mindmap/',
    basePlatformPath: '',
};

export function getTrackingID(trackingId?: string): string {
    return 'G-WTQWL768HY'; // LMS Site vitrine dev tracking ID
}

export function getTrackingIDMatomo(trackingId?: string): string {
    return '38'; // default
}

export function getTrackingIDGAds(trackingId?: string): string {
    return ''; // default
}

export function getTrackingIDGTM(trackingId?: string): string {
    return ''; // default
}

export function getTrackingIDMetaPixel(trackingId?: string): string {
    return ''; // default
}

export function getTrackingIDSnapchat(trackingId?: string): string {
    return ''; // default
}

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
