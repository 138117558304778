import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'replaceText',
})
export class ReplaceTextPipe implements PipeTransform {
    transform(value: string, ...args: { [key: string]: string }[]) {
        return args.reduce(
            (acc, batch) =>
                Object.keys(batch).reduce(
                    (batchAcc, key) => batchAcc.split(key).join(batch[key]), // Mieux que replace parceque multiple
                    acc
                ),
            value
        );
    }
}
