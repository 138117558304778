import * as _ from 'lodash-es';

import {ActivatedRoute, Router} from '@angular/router';
import {CollectionOptionsInterface, DataEntity} from 'octopus-connect';
import {
    Component, EventEmitter,
    Input,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import {MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig} from '@angular/material/legacy-dialog';
import {Observable, Subscription, of, ReplaySubject} from 'rxjs';
import {displayHeader} from 'app/settings';
import {filter, mergeMap, switchMap, take, takeUntil, tap} from 'rxjs/operators';

import {AssignModalComponent} from 'fuse-core/components/card/assign-modal/assign-modal.component';
import {AssignationConfigurationService} from '@modules/assignation/core/services/assignation-configuration.service';
import {AssignationModalDataInterface} from 'fuse-core/components/card/assign-modal/assignation-modal-data.interface';
import {AssignationService} from '@modules/assignation/core/services/assignation.service';
import {
    AssignmentAdaptativePopinComponent
} from '@modules/assignation/core/components/assignment-adaptative-popin/assignment-adaptative-popin.component';
import {
    AssignmentWarningModalComponent
} from '@modules/assignation/core/components/assignment-warning-modal/assignment-warning-modal.component';
import {AuthenticationService} from '@modules/authentication';
import {CommunicationCenterService} from '@modules/communication-center';
import {FlagService} from 'app/shared/flag.service';
import {
    FollowedListComponentOptionsInterface
} from '@modules/assignation/core/components/followed-list/followed-list-component-options.interface';
import {FuseConfirmDialogComponent} from 'fuse-core/components/confirm-dialog/confirm-dialog.component';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {
    RemoveMultipleAssignmentsConfirmComponent
} from '../remove-multiple-assignments-confirm/remove-multiple-assignments-confirm.component';
import {TranslateService} from '@ngx-translate/core';
import {TypedDataEntityInterface} from 'shared/models/octopus-connect/typed-data-entity.interface';
import {UserDataEntity} from '@modules/authentication/core/models/user-data-entity.type';
import {WinsSummaryComponent} from 'fuse-core/components/wins-summary/wins-summary/wins-summary.component';
import {fuseAnimations} from 'fuse-core/animations';
import {
    DynamicWrapperComponent
} from '@modules/assignation/core/components/followed-list/dynamic-wrapper/dynamic-wrapper.component';
import {AsyncRules} from '@modules/assignation/core/models/rules';
import {AuthorizationService} from '@modules/authorization';
import {AutoUnsubscribeTakeUntilClass} from 'shared/models/auto-unsubscribe-take-until.class';
import {ContextualService} from '@modules/assignation/core/services/contextual.service';
import {ProgressionComponent} from '@fuse/components/progression/progression.component';
import {AssignmentByStepsComponent} from '@modules/assignation/core/components/assignment-by-steps/assignment-by-steps.component';
import {v4 as uuidv4} from 'uuid';

@Component({
    selector: 'app-followed-list',
    templateUrl: './followed-list.component.html',
    styleUrls: ['./followed-list.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class FollowedListComponent extends AutoUnsubscribeTakeUntilClass implements OnInit {
    @Input() public options: FollowedListComponentOptionsInterface;
    @Input() showAllStates = false;

    @Input() isAssignationClosedActive = false;
    @Input() public origin = ''; // determine where the filters are displayed

    public isUserHasTrainerRight: boolean;
    public dataSource = new MatTableDataSource();
    public displayedFilters: string[] = [];
    public conditionalFilters: string[] = [];
    public displayedColumns: string[] = [];
    public allTypes = [];
    public allStates: TypedDataEntityInterface<{}>[] = [];
    public displayHeader = false;
    public countEntities = 50;
    public pageIndex = 0;
    public pageRangeOptions = [10];
    public learnersList = [];
    public educationalLevels: TypedDataEntityInterface<{}>[] = [];
    public concepts: TypedDataEntityInterface<{}>[] = [];
    public chapters: TypedDataEntityInterface<{}>[] = [];
    public selectAll: boolean;
    public checkboxes: { [id: number | string]: boolean } = {};
    private currentUser: UserDataEntity;
    private resourcesSubscription: Subscription;
    private resources = [];
    private dialogTitle: string;
    private tooltipDeAssign: string;
    private dialogBody: string;
    private dialogYes: string;
    private dialogNo: string;
    private optionsInterface: CollectionOptionsInterface;
    private pageRange = 10;
    private typologies: { id: string; label: string; localized: string }[];
    private activityExternalTypology: { id: string; label: string; localized: string };
    public displayFilter: boolean;
    public assignmentsFilteredByUniqueGroupsAndConcept: DataEntity[] = [];

    constructor(
        public assignationService: AssignationService,
        private contextualService: ContextualService,
        private config: AssignationConfigurationService,
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthenticationService,
        private authorization: AuthorizationService,
        private dialog: MatDialog,
        private translate: TranslateService,
        private communicationCenter: CommunicationCenterService,
        private flagService: FlagService
    ) {
        super();

        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((userData: UserDataEntity) => {
                if (userData) {
                    this.currentUser = userData;
                } else {
                    this.isUserHasTrainerRight = false;
                }
            });

        this.displayHeader = displayHeader;

        this.translate
            .get('assignment.unassign')
            .subscribe((translation: string) => (this.dialogTitle = translation));
        this.translate
            .get('assignment.unassign.tooltip')
            .subscribe((translation: string) => (this.tooltipDeAssign = translation));
        this.translate
            .get('assignment.confirm_unassign')
            .subscribe((translation: string) => (this.dialogBody = translation));
        this.translate
            .get('generic.yes')
            .subscribe((translation: string) => (this.dialogYes = translation));
        this.translate
            .get('generic.cancel')
            .subscribe((translation: string) => (this.dialogNo = translation));

        this.assignationService.loadAssignationsTypes().subscribe((types) => {
            this.allTypes = types;
        });

        this.authorization.currentUserCan<Observable<boolean>>(AsyncRules.IsUserHasTrainerRight)
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe((isAuthorized) => {
                this.isUserHasTrainerRight = isAuthorized;
            });
        this.communicationCenter
            .getRoom('activities')
            .getSubject('typologies')
            .pipe(
                tap((typologies: { id: string, label: string, localized: string }[]) => this.typologies = typologies),
                tap((typologies: { id: string, label: string, localized: string }[]) => this.activityExternalTypology = typologies.find((typology) => typology.label === 'EXT'))
            )
            .subscribe();
    }

    public get stateWithIcon(): boolean {
        return this.options.stateWithIcon;
    }

    public get rolesCanShowBannerInfo(): string[] {
        return this.config.rolesCanShowBannerInfo();
    }

    public get rolesCanShowBannerInfoClosedAssignment(): string[] {
        return this.config.rolesCanShowBannerInfoClosedAssignment();
    }

    private get stateFromDate(): boolean {
        return this.options.getStateFromDate;
    }

    public ngOnInit(): void {
        this.allStates = this.assignationService.states;
        this.conditionalFilters = this.options.conditionalFilters;
        this.displayedFilters = this.options.filters;
        this.conditionalFilters = this.options.conditionalFilters;
        this.displayedColumns = this.options.columns;


        this.communicationCenter
            .getRoom('licenses')
            .getSubject('methods')
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(
                (
                    entities: TypedDataEntityInterface<{
                        access?: { id?: string | number };
                    }>[]
                ) => {
                    //TODO c'est pas au composant d'appliquer ça au service
                    this.assignationService.learnerMethods = entities.map((entity) => {
                        return _.get(entity, 'attributes.access.id');
                    });
                }
            );

        this.assignationService
            .getEducationalLevels()
            .subscribe((educationalLevels) => {
                this.educationalLevels = educationalLevels;
            });

        this.assignationService
            .getConcepts()
            .subscribe((concepts) => {
                this.concepts = concepts;
            });

        this.assignationService
            .getChapters()
            .subscribe((chapters) => {
                this.chapters = chapters;
            });

        if (this.displayedFilters.includes('schoolyear')) {
            this.setSchoolYears();
        }

        this.learnersList = this.assignationService.learnersList;

        this.setupContextual();

        if (this.shouldDisplayFilters() === false) {
            return this.fixNoDataRequest();
        }
    }

    private setupContextual(): void {
        this.contextualService.actionLessonPlay$
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(() => this.playFirstAvailableOrPending());
        this.contextualService.actionLessonSummary$
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(() => this.openSummaryFirstValid());
        this.contextualService.conditionLessonAvailable$
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe((callback) => callback(!!this.getFirstAvailableOrPending()));
        this.contextualService.conditionSummaryAvailable$
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe((callback) => callback(!!this.getSummaryFirstValid()));
        this.contextualService.dataAssignmentFirstAvailableName$
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe((callback) => callback(this.getFirstAvailableOrPendingName()));
        this.contextualService.dataAssignmentFirstValidAssigneeName$
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe((callback) => callback(this.getSummaryFirstValidAssigneeName()));
    }

    public getTooltipDeAssignLabel(assignation): string {
        return this.cantBeDeAssign(assignation) ? this.tooltipDeAssign : null;
    }

    public launchSearch(optionInterface?: CollectionOptionsInterface): void {
        if (this.resourcesSubscription) {
            this.resourcesSubscription.unsubscribe();
        }
        if (optionInterface) {
            this.optionsInterface = optionInterface;
        }

        this.resourcesSubscription = this.refreshList();
    }

    public onPaginateChange(event): void {
        this.assignationService.assignmentsPaginated.paginator.page =
            event.pageIndex + 1;
    }

    /*
     * if we have hideFeedbacks to false  that mean that before opening assignment we have to open a modal
     * to explain learner he will do a special lesson to evaluated him. it's only for adaptative lesson when IA doesn't
     * know learner before this flag is also use to hide feedback of IA
     * @param assignment
     * TODO MISE EN STAND BY CAR ON NE PASSE PLUS PAR CES VERIFICATIONS POUR  L'INSTANT
     */
    public openAssignment(assignment: DataEntity): void {
        if (this.options.disableOpening) {
            return null;
        }

        const hideFeedbacks = assignment.get('hideFeedbacks');
        // hideFeedbacks = false = hide feedback because we are in adaptive lesson with diagnostic and so we doesn't open modal
        // to say learner will do a special lesson
        // eslint-disable-next-line max-len
        if (!this.options.useHideFeedbacksToLaunchAdaptativeModal ||
            hideFeedbacks === null ||
            hideFeedbacks === undefined ||
            hideFeedbacks === 0 ||
            hideFeedbacks === false
        ) {
            this.launchAssignment(assignment);
        } else {
            const dialogConfig = new MatDialogConfig();
            dialogConfig.data = {
                svg: 'adaptive-begin',
                body: 'generic.adaptive.begin',
                button: 'generic.start',
            };
            dialogConfig.panelClass = 'adaptative-popin';
            dialogConfig.disableClose = true;

            const dialogRef = this.dialog.open(
                AssignmentAdaptativePopinComponent,
                dialogConfig
            );

            dialogRef.afterClosed().subscribe(() => {
                this.launchAssignment(assignment);
            });
        }
    }

    /**
     * set the title of assignment :
     * simple assignment : use title in assignment if only one lesson to launch,
     * multi lesson  : one assignment with multiple lesson : use main title or first title of array assignment_nodes
     * @param assignment current assignment for the row
     */
    public getTitleAssignment(assignment: DataEntity): string {
        let title = '';
        if (assignment.get('assignated_node')) {
            title = assignment.get('assignated_node').title;
        } else {
            if (assignment.get('title')) {
                title = assignment.get('title');
            } else {
                title = assignment.get('assignated_nodes')[0].title;
            }
        }
        return title;
    }

    public getMultipleGroupsLabels(assignment: DataEntity): string {
        if (assignment && assignment.get('groups') && Array.isArray(assignment.get('groups'))) {
            return assignment.get('groups').map(group => group.label).join(', ');
        }
        return '';
    }

    public getGroupLabel(group): string {
        if (group?.groupname) {
            return group.groupname;
        }
        if (group?.workgroupname) {
            return group.workgroupname;
        }
        return '';
    }

    public getGroupColor(group): string {
        if (!!group?.color) {
            return group.color;
        }
        return 'aquamarine';
    }

    /**
     * retourne un tableau contenant les classes ett groupes d'une assignation
     * TODO: exporter les types Group et Workgroup present dans groups-management module
     * @param assignment
     */
    public getRawGroupsAndWorkgroupsInAssignment(assignment: DataEntity): any[] {
        const groups = this.assignationService.groupsList;
        const workgroups = this.assignationService.workgroupsList;

        const groupsAndWorkgroups = groups.concat(workgroups).filter((group) => assignment.get('groups').map((g) => +g.id).includes(+group.id));
        if (groupsAndWorkgroups.length === 0 && assignment.get('groups').length) {
            return assignment.get('groups').map((group) => {
                return {
                    id: group.id || uuidv4(),
                    groupname: group.label || 'generic.deleted',
                };
            });
        }
        return groupsAndWorkgroups;
    }

    public getConceptLabel(assignment: DataEntity): string {
        if (assignment?.get('assignated_node')?.concepts
            && Array.isArray(assignment?.get('assignated_node')?.concepts)
            && assignment?.get('assignated_node')?.concepts.length) {
            return assignment?.get('assignated_node')?.concepts[0].label;
        }
        return '';
    }

    public getConsultedAssignment(assignment: DataEntity): string {
        return assignment.attributes.consulted.consulted_bool;
    }

    public unAssign(assignment: DataEntity): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            titleDialog: this.dialogTitle,
            bodyDialog: this.dialogBody,
            labelTrueDialog: this.dialogYes,
            labelFalseDialog: this.dialogNo,
        };

        this.dialog
            .open(FuseConfirmDialogComponent, dialogConfig)
            .afterClosed()
            .pipe(
                filter((confirm) => confirm),
                mergeMap(() => this.removeAssignment(assignment))
            )
            .subscribe();
    }

    public localizedState(state: string): string {
        if (state && !Array.isArray(state)) {
            return `assignment.state.${state}`;
        }
    }

    public isAtLeastTrainer(): boolean {
        return this.authService.isAtLeastTrainer();
    }

    public checkState(assignment): string {
        const state =
            (assignment.get('state_term') && assignment.get('state_term').label) ||
            assignment.get('state');

        if (!this.stateFromDate) {
            if (assignment.get('type_term')?.label === 'training' && state === 'assigned') {
                return 'available';
            }
            return state;
        } else {
            if (assignment) {
                const valid = this.dateIsValid(
                    state,
                    assignment.get('type_term'),
                    assignment.get('dates')
                );
                if (valid) {
                    return valid.state;
                }
            }

            return 'assigned';
        }
    }

    public getIconState(assignment): string {
        const state = this.checkState(assignment);
        switch (state) {
            case 'assigned':
                return 'tap_check';
            case 'pending':
                return 'hourglass';
            case 'closed':
                return 'chat_close';
        }
    }

    public checkAccess(user: string[]): boolean {
        if (user) {
            return this.authService.hasLevel(user);
        }
        return false;
    }

    public getGrade(assignation): string {
        if (assignation) {
            if (this.checkState(assignation) === 'correct') {
                if (+assignation.get('progress') === 0) {
                    return '-';
                } else {
                    const newGrade = Math.min(
                        20,
                        Math.round(assignation.get('grade') * 10) / 10
                    );

                    return newGrade.toString() + '/20';
                }
            }
        }
        return '';
    }

    public getProgress(assignation): string {
        if (assignation) {
            return (Math.round(+assignation.get('progress')) || '0') + '%';
        }

        return '';
    }

    public cantBeDeAssign(assignation): boolean {
        return this.options.isRestrictionForDeAssign && +assignation.get('progress') > 0;
    }

    public getEducationalLevels(educationalLevels: any): string {
        return educationalLevels
            ? educationalLevels.map((e) => e.label).join(', ')
            : '';
    }

    public getConcepts(concepts: any): string {
        return concepts
            ? concepts.map((e) => e.label).join(', ')
            : '';
    }

    public displayButton(buttonIdentifier: string): boolean {
        return this.options.allowedActions.includes(buttonIdentifier);
    }

    /**
     * edit lesson of the current asignment
     * @param row
     */
    public editLessonBehindTheAssignment(row: DataEntity): void {
        // TODO brancher nouvel editeur
    }

    /**
     * edit comment of current assignment
     */
    public editAssignment(
        assignment: DataEntity
    ): void {
        let titleDialog = '';
        let bodyDialog = '';
        let labelConfirmDialog = '';

        this.translate
            .get('assignment.edit_title')
            .subscribe((translation: string) => (titleDialog = translation));
        this.translate
            .get('assignment.edit_body')
            .subscribe((translation: string) => (bodyDialog = translation));
        this.translate
            .get('assignment.edit_confirm')
            .subscribe((translation: string) => (labelConfirmDialog = translation));

        const dialogConfig = new MatDialogConfig<AssignationModalDataInterface>();
        dialogConfig.data = {
            askComment: this.canEditAssignmentComment(),
            askTitle: this.canEditAssignmentTitle(),
            commentDefault: assignment.get('comment'),
            titleDefault: assignment.get('assignated_node')?.title,
            body: bodyDialog,
            header: titleDialog,
            validLabel: labelConfirmDialog,
            commentMaxLength: this.config.getEditableAssignmentCommentMaxLength(),
            learners: this.assignationService.learners,
            classes: this.assignationService.classes,
            workgroups: this.assignationService.workgroups,
            assignment: assignment
        };

        dialogConfig.panelClass = 'edit_assignment_comment_modal';

        this.dialog
            .open(AssignModalComponent, dialogConfig)
            .afterClosed()
            .subscribe((result) => {
                if (result) {
                    if (!!result.comment) {
                        assignment.set('comment', result.comment);
                    }

                    if (!!result.title) {
                        assignment.set('title', result.title);
                    }

                    this.assignationService.saveAssignment(assignment);
                }
            });
    }

    /**
     * Get list of difficulties label as an only one string
     * @param assignment
     */
    public getDifficulties(assignment: DataEntity): string {
        return _.get(assignment, 'attributes.assignated_node.difficulty', [])
            .map((difficulty) => difficulty.label)
            .join();
    }

    /**
     * Get list of chapters label as an only one string
     * @param assignment
     */
    public getChapters(assignment: DataEntity): string {
        return _.get(assignment, 'attributes.assignated_node.chapters', [])
            .map((chapter) => chapter.label)
            .join();
    }

    public delete(assignment: DataEntity): void {
        this.dialog.open(AssignmentWarningModalComponent).afterClosed().pipe(
            filter((isConfirm) => isConfirm),
            mergeMap(() => this.removeAssignment(assignment))
        )
            .subscribe();
    }

    /**
     * open the modal of gratifications when clic on cup
     * @param $event event to stop propagation
     * @param row current dataentity selected
     */
    public openGratification($event: any, row: DataEntity): void {
        $event.stopPropagation();
        // fake data to replace with real one when will exist
        if (row.get('gratification')) {
            const dialogRef = this.dialog.open(WinsSummaryComponent, {
                panelClass: 'feedback-earning-dialog',
                data: row.get('gratification'),
            });
        }
    }

    public shouldDisplayFilters(): boolean {
        return this.displayedFilters.length > 0;
    }

    private refreshList(): Subscription {
        this.optionsInterface.filter['excludeAssignator'] =
            this.options.excludeAssignator;
        if (this.showAllStates) {
            // teacher case : all state must be shown with no filter
        } else {
            // learner case : data are separate in two tabs
            if (this.isAssignationClosedActive) {
                this.optionsInterface.filter['assignments_state'] = this.config.assignmentsStateIds().closed + ',' + this.config.assignmentsStateIds().valid;
            } else {
                this.optionsInterface.filter['assignments_state'] = this.config.assignmentsStateIds().pending + ',' + this.config.assignmentsStateIds().assigned;
            }
        }

        return (this.resourcesSubscription = this.assignationService
            .loadPaginatedAssignments(this.optionsInterface)
            .pipe(
                takeUntil(this.unsubscribeInTakeUntil),
                mergeMap((assignments) => {
                    this.assignmentsFilteredByUniqueGroupsAndConcept = [];
                    const assignmentHasSameConcept = (a, b) =>
                        a.get('assignated_node').concepts[0]?.id === b.get('assignated_node').concepts[0]?.id;
                    const compareArrays = (a, b) =>
                        a.length === b.length && a.every((element, index) => element === b[index]);
                    const isAssignmentHasSameGroupsAndConcept = (a, b) =>
                        a.length && a.some((c) =>
                            assignmentHasSameConcept(b, c) && compareArrays(b.get('groups').map((group) => +group.id), c.get('groups').map((group) => +group.id))
                        );

                    assignments.forEach((assignment) => {
                        if (!!assignment.get('groups').length && !isAssignmentHasSameGroupsAndConcept(this.assignmentsFilteredByUniqueGroupsAndConcept, assignment)) {
                            this.assignmentsFilteredByUniqueGroupsAndConcept.push(assignment);
                        }
                    });
                    console.log('assignments', this.assignmentsFilteredByUniqueGroupsAndConcept);
                    return of(assignments);
                })
            )
            .subscribe((resources) => {
                this.resources = resources;
                this.assignationService.assignations = this.resources;
                this.resetCheckboxes();
                if (!this.resources) {
                    return;
                }
                this.dataSource.data = this.resources;
                this.setPaginator();
            }));
    }

    private setPaginator(): void {
        if (this.assignationService.assignmentsPaginated.paginator) {
            this.countEntities =
                this.assignationService.assignmentsPaginated.paginator.count;
            this.pageIndex =
                this.assignationService.assignmentsPaginated.paginator.page - 1;
            this.pageRange =
                this.assignationService.assignmentsPaginated.paginator.range;
        }
    }

    /**
     * launch the selected assignment
     * @param assignment DataEntity
     * @private
     * * TODO MISE EN STAND BY CAR ON NE PASSE PLUS PAR CES VERIFICATIONS POUR  L'INSTANT
     */
    private launchAssignment(assignment: DataEntity): void {
        const navigationOptions: { [key: string]: any } = {
            exitLessonUrl: this.router.url,
        };
        if (this.haveToOverrideAssignmentLaunchToMetadatas()) {
            if (
                assignment.get('type_term') &&
                assignment.get('type_term').label === 'init'
            ) {
                this.communicationCenter
                    .getRoom('lessons')
                    .next('openLessonMetadataDialog', {
                        lessonId: assignment.get('assignated_nodes').id,
                    });
            } else {
                this.communicationCenter
                    .getRoom('lessons')
                    .next('openLessonMetadataDialog', {
                        lessonId: assignment.get('assignated_node').id,
                    });
            }
        } else {
            // mono assignement
            const assignated_node = assignment.get('assignated_node');
            // multi assignement
            const assignated_nodes = assignment.get('assignated_nodes');
            let type = '';
            let idLesson = '';
            if (assignated_node === null && assignated_nodes.length > 0) {
                // in case of multi assignation we always take the info of the first assignation to launch
                // in a future us we will take the assignation where user stop before if he begin and stop before end
                type = assignated_nodes[0].type;
                idLesson = assignated_nodes[0].id;
            } else if (assignated_node !== null) {
                type = assignated_node.type;
                idLesson = assignated_node.id;
            } else {
                console.error(
                    'fol-334 : error because assignated_node is null and assignated_nodes.length <=0'
                );
                return;
            }

            const valid = this.dateIsValid(
                assignment.get('state'),
                assignment.get('type_term'),
                assignment.get('dates')
            );
            if (valid && valid.available) {
                this.assignationService
                    .launchAssignment(assignment, true)
                    .pipe(
                        filter((isAllowed) => !!isAllowed),
                        tap(() => {
                            let flaggingId;
                            // add value to flaggingId to patch only if current user = flag user
                            if (
                                assignment.get('consulted').consulted_bool &&
                                assignment.get('consulted').uid === this.currentUser.id
                            ) {
                                flaggingId = assignment.get('consulted').flagging_id;
                            }
                            // create or edit flag only if current user is assignated user
                            if (
                                assignment.get('assignated_user').uid === this.currentUser.id
                            ) {
                                this.flagService.updateFlagEntity(
                                    assignment,
                                    'assignations',
                                    'consulted_assignation',
                                    flaggingId
                                );
                            }

                            if (type === 'form') {
                                // partie sur savanturiers
                                const route = ['..', 'assignment', 'forms', idLesson, 'player'];
                                this.router.navigate(route, {relativeTo: this.route});
                            } else {
                                this.loadAndNavigateToLesson(assignment, navigationOptions);
                            }
                        }),
                        take(1)
                    )
                    .pipe(takeUntil(this.unsubscribeInTakeUntil))
                    .subscribe();
            }
        }

    }

    public showRecap(assignment): void {
        const navigationOptions: { [key: string]: any } = {
            exitLessonUrl: this.router.url,
        };
        if (this.userNavigateDirectlyToSummary(assignment)) {
            navigationOptions.navigateDirectlyToSummary = true;
            navigationOptions.assignatedUserId = assignment.get('assignated_user').uid;
            this.loadLessonById(assignment.get('assignated_node').id).pipe(
                take(1),
                filter((lesson: DataEntity) => !!lesson),
                mergeMap((lesson: DataEntity) => {
                    if (lesson.get('reference').some((activity) => activity.type === 'activity')) {
                        return of(0);
                    }
                    return this.openModalWithActivities(lesson, assignment, true);
                }),
                filter((startOnStepIndex: number) => !!startOnStepIndex || startOnStepIndex === 0),
                tap((startOnStepIndex: number) => navigationOptions.startOnStepIndex = startOnStepIndex),
                tap(() => this.loadAndNavigateToLesson(assignment, navigationOptions)),
            ).subscribe();
        }
    }

    public continueAssignment(assignment, retry?: boolean): void {
        if (!retry || (retry && this.options.isRetryEnabledOnClickInAssignmentTitle)) {
            this.communicationCenter
                .getRoom('assignment')
                .getSubject('current')
                .next(assignment);
            const navigationOptions: { [key: string]: any } = {
                exitLessonUrl: this.router.url,
            };
            if (+this.currentUser.id !== +assignment.get('assignated_user').uid) {
                navigationOptions.preview = true;
            }
            this.loadLessonById(assignment.get('assignated_node').id).pipe(
                take(1),
                filter((lesson: DataEntity) => !!lesson),
                mergeMap((lesson: DataEntity) => {
                    if (lesson.get('reference').some((activity) => activity.type === 'activity')) {
                        return of(0);
                    }
                    return this.openModalWithActivities(lesson, assignment);
                }),
                tap((startOnStepIndex: number) => navigationOptions.startOnStepIndex = startOnStepIndex),
                tap(() => this.loadAndNavigateToLesson(assignment, navigationOptions)),
            ).subscribe();
        }
    }

    openModalWithActivities(lesson: DataEntity, assignment: DataEntity, forDisplayingRecap?: true): Observable<number> {
        const data = {
            // title of wrapper can be different from inputData.title
            title: lesson.get('metadatas').title,
            // inputData contient les données à injecter lors de la creation du component via factory d'Angular (cf: dynamic component)
            inputData: {
                title: lesson.get('metadatas').title,
                list: lesson.get('reference').map((activity) => ({
                    label: activity.title, value: activity.id, disabled: forDisplayingRecap ? this.isBtnDisabled(activity.id, assignment) : false
                }))
            },
            noModalContext: true,
            outputEvent: new EventEmitter<{ label: string, value: string }>()
        };

        return this.communicationCenter
            .getRoom('assignment')
            .getSubject('view').pipe(take(1),
            switchMap(comp => {
                if (comp.name === 'AssignmentByStepsComponent') {
                    const dialogRef = this.dialog.open(AssignmentByStepsComponent, {
                        data: {
                            origine: 'launchInFollowedHelp', // pas de gestion groupeleve assignement already exist
                            nodeId: lesson.id,
                            node: lesson,
                            activities: null,
                            assignatedTo: assignment.get('assignated_user')?.classes, // class and group assignated
                            seances: lesson.get('reference').map((activity) => ({
                                label: activity.title, value: activity.id
                            })),
                        }
                    });

                    return dialogRef.afterClosed()
                        .pipe(
                            filter((act) => !!act),
                            mergeMap((act) => of(lesson.get('reference').findIndex((activity) => activity.id === act.sceances.filter(s => s.selected)[0].value)))
                        );
                } else {
                    const dialogRef = this.dialog.open(DynamicWrapperComponent, {data});
                    return dialogRef.afterClosed()
                        .pipe(
                            filter((act) => !!act),
                            mergeMap((act) => of(lesson.get('reference').findIndex((activity) => activity.id === act.value)))
                        );
                }
            }));
    }

    private isBtnDisabled(activityId: string | number, assignment: DataEntity): boolean {
        if (assignment?.get('config')) {
            const config: { [key: string]: any } = JSON.parse(assignment.get('config'));
            if (config[activityId]) {
                return false;
            }
        }
        return true;
    }

    private loadLessonById(subLessonId: string | number): Observable<DataEntity> {
        const lesson$ = new ReplaySubject<Observable<DataEntity>>(1);

        this.communicationCenter
            .getRoom('lessons')
            .next('getLesson', {
                lessonId: subLessonId,
                callbackSubject: lesson$
            });

        return lesson$.pipe(
            mergeMap(obs => obs)
        );
    }

    public isUserCanContinueHisOwnAssignment(assignment: DataEntity): boolean {
        return +this.currentUser.id === +assignment.get('assignated_user').uid && this.checkState(assignment) !== 'closed';
    }

    public isUserCanSeeAssignmentRecap(assignment: DataEntity): boolean {
        if (this.isUserHasTrainerRight
            && +this.currentUser.id !== +assignment.get('assignated_user').uid && !this.isAssignmentContainExternalTypologies(assignment)) {
            if (assignment.get('assignated_node')?.typologies?.length) {
                return assignment.get('state_term')?.label === 'valid'
                    || assignment.get('state_term')?.label === 'closed'

            }
            return assignment.get('state_term')?.label === 'valid'
                || assignment.get('state_term')?.label === 'closed'
                || assignment.get('state_term')?.label === 'pending';
        }
        return false;
    }

    // on test si ce sont des iframe dans le activitées
    private isAssignmentContainExternalTypologies(assignment: DataEntity): boolean {
        const typologies: string[] = assignment.get('assignated_node').typologies as string[];
        return typologies.length > 0 ? typologies.every(typology => typology === this.activityExternalTypology?.id) : false;
    }

    /**
     * reset checkbox to initial state
     * @private
     * @params: hardReset initialise all checkboxes to false, used after a multiple suppression
     */
    private resetCheckboxes(hardReset?: boolean): void {
        if (hardReset) {
            for (const entity of this.resources) {
                // in case of hard reset used after multiple suppression, initialise all checkboxes
                this.selectAll = false;
                this.checkboxes = {};
                this.checkboxes[entity.id] = false;
            }
        }
        this.selectAll = !this.resources.some((resource) => !this.checkboxes[resource.id]);

    }

    /**
     * update all the checkbox when "selectAll" is updated
     * @param event
     */
    public updateCheck(event): void {
        this.selectAll = event.checked;
        this.resources.forEach((resource) => this.checkboxes[resource.id] = event.checked);
    }

    /**
     * remove multiple assignments then reset all checkboxes
     */
    public removeMultipleAssignment(): void {
        const dialogRef = this.dialog.open(
            RemoveMultipleAssignmentsConfirmComponent
        );
        dialogRef
            .afterClosed()
            .pipe(
                mergeMap((isRemoveActionConfirmed: boolean) => {
                    if (isRemoveActionConfirmed) {
                        const ids = [];
                        for (const id in this.checkboxes) {
                            if (this.checkboxes[id] === true) {
                                ids.push(id);
                            }
                        }
                        return this.assignationService.removeMultipleAssignment(ids).pipe(
                            take(1),
                            tap(() => this.resetCheckboxes(true)),
                            tap(() => this.refreshList())
                        );
                    }
                    return of(null);
                })
            )
            .subscribe();
    }

    /**
     * used to know if an assignment is selected (checked true)
     */
    public get isAssignmentsSelected(): boolean {
        const checkboxesValues = [];
        for (const id in this.checkboxes) {
            checkboxesValues.push(this.checkboxes[id]);
        }
        return checkboxesValues.some((value: boolean) => value === true);
    }

    public isMultipleActionWithCheckBoxes(): boolean {
        return this.config.isMultipleActionWithCheckBoxes() && this.isAtLeastTrainer();
    }

    public loadAndNavigateToLesson(
        assignment: DataEntity,
        navigateOptions?: { [key: string]: any }
    ): void {
        this.communicationCenter
            .getRoom('lessons')
            .getSubject('playLesson')
            .next({assignment, navigateOptions});
    }

    private dateIsValid(state, type, date): any {
        const startDate = date ? date.value : null;
        const endDate = date ? date.value2 : null;
        const dateNow = Math.floor(Date.now() / 1000);

        if (type && this.assignationService.hasCompletionDate(type.label)) {
            if (startDate && startDate < dateNow && endDate && endDate > dateNow) {
                if (state === 'closed') {
                    return {
                        state: 'valid',
                        available: true,
                    };
                }
            } else if (
                startDate &&
                startDate > dateNow &&
                endDate &&
                endDate > dateNow
            ) {
                return {
                    state: 'assigned',
                    available: false,
                };
            } else if (endDate && endDate < dateNow) {
                return {
                    state: 'correct',
                    available: true,
                };
            }
        }

        if (startDate && startDate > dateNow && type.label === 'training') {
            return {
                state: 'assigned',
                available: false,
            };
        }

        return {
            state: 'available',
            available: true,
        };
    }

    /**
     * set the shoolYearsList begin in 2017 and set current year to default filter
     */
    private setSchoolYears(): void {
        this.assignationService.loadSchoolyears().subscribe((entities) => {
            this.assignationService.schoolYearsList = entities;
            // default sort to current year
            // not use anymore this.optionsInterface.filter['schoolYearDates'] = this.assignationService.currentSchoolYearBegin;
        });
    }

    private removeAssignment(assignment: DataEntity): Observable<boolean> {
        return this.assignationService.deleteAssignment(assignment).pipe(
            filter((success) => success),
            tap(() => this.assignationService.assignmentsPaginated.paginator.reload())
        );
    }

    private haveToOverrideAssignmentLaunchToMetadatas(): boolean {
        const rolesToOverride: string[] =
            this.options.rolesForOverrideAssignmentLaunchToMetadatas;

        return (
            rolesToOverride.length > 0 &&
            (rolesToOverride.includes(this.authService.accessLevel) ||
                rolesToOverride.includes('default'))
        );
    }

    /**
     * Send a default request.
     * This method will must not exist we wait a search-filters refacto.
     *
     * Why we need it ?
     * If there's no filters, the filter component is not displayed
     * But it's the one who launch the request (this should be a parent responsability)
     * @private
     */
    private fixNoDataRequest(): void {
        this.launchSearch({
            filter: this.options.initialRequestFilters,
            page: 1,
            range: 10,
        });
    }

    private canEditAssignmentComment(): boolean {
        return this.config.getEditableAssignmentFields().includes('comment');
    }

    private canEditAssignmentTitle(): boolean {
        return this.config.getEditableAssignmentFields().includes('title');
    }

    private userNavigateDirectlyToSummary(assignment: DataEntity): boolean {
        return this.options.navigateDirectlyToSummary && this.currentUser.id === assignment.get('assignator').uid
            && assignment.get('assignator').uid !== assignment.get('assignated_user').uid;
    }

    private getFirstAvailableOrPending(): DataEntity {
        return this.resources.find((assignment: DataEntity) => {
            const state = this.checkState(assignment);
            return (state === 'available' || state === 'pending') && this.isUserCanContinueHisOwnAssignment(assignment);
        });
    }

    private getFirstAvailableOrPendingName(): string {
        const assignmentMatch = this.getFirstAvailableOrPending();

        return assignmentMatch ? assignmentMatch.get('title') : '';
    }

    private getSummaryFirstValid(): DataEntity {
        return this.resources.find((assignment: DataEntity) => {
            return this.isUserCanSeeAssignmentRecap(assignment);
        });
    }

    private getSummaryFirstValidAssigneeName(): string {
        const assignmentMatch = this.getSummaryFirstValid();

        return assignmentMatch ? assignmentMatch.get('assignated_user').name : '';
    }

    private playFirstAvailableOrPending(): void {
        const assignmentMatch = this.getFirstAvailableOrPending();

        if (assignmentMatch) {
            this.continueAssignment(assignmentMatch);
        }
    }

    private openSummaryFirstValid(): void {
        const assignmentMatch = this.getSummaryFirstValid();

        if (assignmentMatch) {
            this.showRecap(assignmentMatch);
        }
    }

    public openProgression(assignment: DataEntity, retryAssignment?: boolean) {
        if (this.isUserCanOpenProgression(assignment)) {
            this.communicationCenter
                .getRoom('assignment')
                .next('assignmentToContinue', assignment);
            this.dialog.open(ProgressionComponent, {
                panelClass: `progression`,
                data: {
                    assignment: assignment,
                    retryAssignment: true
                }
            });
        }
    }

    // TODO: MOYEN TEMPORAIRE DE DEFINIR SI LE PARCOURS FAIT PARTI D'UNE COLLECTION AVEC CONTINUITE
    public isUserCanOpenProgression(assignment: DataEntity): boolean {
        const conceptEnglish = 'Anglais';
        const conceptSpanish = 'Espagnol';
        const concepts = assignment.get('assignated_node').concepts || [];
        return this.isProgressionEnable && this.origin === 'self' && !assignment.get('assignated_node').custom
            && !!this.assignmentsFilteredByUniqueGroupsAndConcept.find((a) => a.id === assignment.id)
            && !!concepts.length && concepts.some((concept) => (concept.label === conceptEnglish) || (concept.label === conceptSpanish));
    }

    public displayFilters() {
        this.displayFilter = !this.displayFilter;
    }

    public get isProgressionEnable(): boolean {
        return this.options.isProgressionEnable && this.authService.isAtLeastTrainer();
    }

    public get isGroupsMustBeDisplayedWithChips(): boolean {
        return this.options.isGroupsMustBeDisplayedWithChips;
    }

    public get isRetryEnabledOnClickInAssignmentTitle(): boolean {
        return this.options.isRetryEnabledOnClickInAssignmentTitle;
    }
}
