import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {map, take, takeUntil} from 'rxjs/operators';
import {LaboratoryCardInterface, LanguageLabService} from '@modules/activities/core/language-lab/language-lab.service';
import {OctopusConnectService} from 'octopus-connect';
import {AccountManagementProviderService} from '@modules/account-management';
import {ProfileService} from '@modules/account-management/core/profile/profile.service';
import {FlashcardAttribute} from 'shared/flashcard';

type FlashCardInterface = {id: string} & FlashcardAttribute;

@Component({
    selector: 'app-language-lab',
    templateUrl: './language-lab.component.html',
    styleUrls: ['./language-lab.component.scss']
})
export class LanguageLabComponent {
    public readInProgress = false;
    public cards: LaboratoryCardInterface[] = [];
    public loader = false;
    private page = 1;
    public currentFlashCardReading: FlashCardInterface;
    public idCardPlaying = '';
    public currentCard = null;
    public periods = [];
    periodSelected;
    private player: HTMLAudioElement;
    private destroy$: Subject<void> = new Subject<void>();

    @ViewChild('stream') set playerRef(ref: ElementRef<HTMLAudioElement>) {
        this.player = ref?.nativeElement;
    }

    constructor(private languageLabService: LanguageLabService,
                private changeDetectorRef: ChangeDetectorRef,
                private octopusConnectService: OctopusConnectService,
                private accountManagementProvider: AccountManagementProviderService,
                private profileProvider: ProfileService) {
        this.profileProvider.userInformationOnChanged.subscribe(userInformation => {
            // data of user change reload periods and data to show
            this.setPeriodList();
        });
    }

    private setPeriodList() {
        const concept = this.accountManagementProvider.loggedUser.get('config').concept;
        const educationalLevel = this.accountManagementProvider.loggedUser.get('config').educational_level;
        this.octopusConnectService.loadCollection('chapters', {grade: educationalLevel, concepts: concept})
            .pipe(take(1),
                map(collection => collection.entities),
                map(data => data.map(entity => ({id: entity.id, label: entity.get('label')})))
            ).subscribe(data => {
            this.periods = data;
            this.periodSelected = this.periods[0].id;
            this.loadDataLevel1();
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    /**
     *  if is playing block other card read
     * @param $event 'is-playing' | 'is-selected'
     * @param flashcard
     */
    public onStateChange($event: string, flashcard: FlashCardInterface): void {
        this.resetPlayer();
        if (flashcard?.audio?.uri) {
            this.startPlaying(flashcard);
        }
    }

    openActivity(card: FlashCardInterface): void {
        this.languageLabService.loadLangCard(1, {theme: card.id}, 'laboratory').pipe(
            take(1)
        ).subscribe(c => {
            this.currentCard = card;
            this.currentCard.data = c;
        });
    }

    public close(): void {
        this.currentCard = null;
    }

    public next(): void {
        this.currentCard = null;
    }

    /**
     * when infinite scroll detect event load next data
     */
    public onScroll(): void {
        this.loader = true;
        this.page = this.page + 1;
        this.loadDataLevel1();
    }

    private loadDataLevel1(append = true): void {
        const educationalLevel = this.accountManagementProvider.loggedUser.get('config').educational_level;
        this.languageLabService.loadLangCard(this.page, {
            chapters: this.periodSelected,
            levels: educationalLevel,
            concepts: this.accountManagementProvider.loggedUser.get('config').concept
        }, 'laboratory-theme').pipe(
            takeUntil(this.destroy$)
        ).subscribe(flashcards => {
            if (append) {
                this.cards.push(...flashcards);
            } else {
                this.cards = flashcards;
            }
            this.loader = false;
            this.readInProgress = false;
        });
    }

    private resetPlayer(): void {
        if (this.player) {
            this.player.pause();
            this.player.currentTime = 0;
            this.player.onended = () => {
                this.idCardPlaying = '';
            };
        }
    }

    private startPlaying(flashcard: FlashCardInterface): void {
        this.currentFlashCardReading = flashcard;
        this.idCardPlaying = flashcard.id;
        this.changeDetectorRef.detectChanges();
        this.player.play();
    }

    onSelectionChange(event: any): void {
        this.page = 1;
        this.periodSelected = event;
        this.loadDataLevel1(false);
    }
}

