import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '@fuse/directives/directives';
import { FusePipesModule } from '@fuse/pipes/pipes.module';
import {FuseSidebarModule} from 'fuse-core/components';
import {GraphModule} from 'fuse-core/components/graph/graph.module';
import {NewsModule} from 'fuse-core/news/news.module';
import {FlashcardComponent} from 'fuse-core/components/flashcard/flashcard.component';
import {SharedVideoComponent} from 'fuse-core/components/shared-video/shared-video.component';
import {TranslateModule} from '@ngx-translate/core';
import {SharedMaterialModule} from '../app/shared/shared-material.module';
import {BannerInfoComponent} from "fuse-core/components/banner-info/banner-info.component";
import {TipsComponent} from 'fuse-core/components/tips/tips.component';
import {FormDialogComponent} from './components/form-dialog/form-dialog.component';
import {MatLegacySliderModule} from "@angular/material/legacy-slider";
import {InformationsLegalesComponent} from 'fuse-core/components/informations-legales/informations-legales.component';
import {ReadPdfComponent} from 'fuse-core/components/read-pdf/read-pdf.component';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';
import {UserReviewModule} from 'fuse-core/components/user-review/user-review.module';
import {NpsModule} from 'fuse-core/components/nps/nps.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        FuseDirectivesModule,
        FusePipesModule,
        FuseSidebarModule,
        GraphModule,
        NewsModule,
        TranslateModule,
        SharedMaterialModule,
        MatLegacySliderModule,
        UserReviewModule
    ],
    exports  : [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        FuseDirectivesModule,
        FusePipesModule,
        FuseSidebarModule,
        GraphModule,
        NewsModule,
        FlashcardComponent,
        SharedVideoComponent,
        BannerInfoComponent,
        TipsComponent,
        InformationsLegalesComponent,
        ReadPdfComponent,
        UserReviewModule,
        NpsModule
    ],
    declarations: [
        FlashcardComponent,
        SharedVideoComponent,
        BannerInfoComponent,
        TipsComponent,
        FormDialogComponent,
        InformationsLegalesComponent,
        ReadPdfComponent,
    ],
    providers: [{ provide: MAT_DIALOG_DATA, useValue: {} }]
})
export class FuseSharedModule
{
}
