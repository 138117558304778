import {Component, ElementRef, EventEmitter, inject, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {FillInBlanksChildComponent} from './fill-in-blanks-child/fill-in-blanks-child.component';
import {brand} from '../../../../../settings';
import {Observable, of, ReplaySubject, Subject} from 'rxjs';
import {BaseActivityComponent, FeedbackInterface, TIME_DELAY_BEFORE_SAVE, TIME_DISPLAYING_CORRECTION,} from '@modules/activities/core/player-components/base-activity.component';
import {v4 as uuidv4} from 'uuid';
import {AnswerResultInterface} from '@modules/activities/core/models';
import {ItemAnswerStateEnum} from '../../models/item-answer-state.enum';
import {AnswerInterface} from '@modules/activities/core/models/answer.interface';
import {answerStatusEnum} from '@modules/activities/core/models/answer-status.enum';
import {FillInBlanksActivityGranule} from '@modules/activities/core/models/activities/typologies/fill-in-blanks-activity.granule';

@Component({
    selector: 'app-fill-in-blanks',
    templateUrl: './fill-in-blanks.component.html'
})
export class FillInBlanksComponent extends BaseActivityComponent<FillInBlanksActivityGranule> implements OnInit, OnDestroy {
    @ViewChild(FillInBlanksChildComponent) private child: FillInBlanksChildComponent;
    @Output() answerClick: EventEmitter<string> = new EventEmitter<string>();

    public answerFeedback: string; // feedback fetch from api
    public answerValidated = false;
    public brand = brand;
    public childReadiness: Subject<FillInBlanksChildComponent> = new Subject<FillInBlanksChildComponent>();
    public disableOnChange = new ReplaySubject<void|boolean>(1);
    public ttsChange = new ReplaySubject<{id: string, value: boolean }>(1);
    public feedback: string;
    public fillInBlanksWording: unknown;
    public isCaseSensitive = true;
    public isFormula = false;
    public isSuggestedButtons: boolean;
    public items: unknown[] = [];
    public title: string;
    public btnValidateState = ItemAnswerStateEnum.pristine;
    public userTry = 0;
    private childIsReady: FillInBlanksChildComponent;
    private waitUntilCorrectionFinished: boolean;

    private elementRef = inject(ElementRef)

    constructor() {
        super();
        // Une subtilité du cycle angular fait qu'à un moment donné, deux FillInBlanksChildComponent existent
        //  Mais le ViewChild derrière "this.child" possède le dernier et qu'il nous faut le différencier du nouveau
        this.childReadiness.subscribe((child: FillInBlanksChildComponent) => {
            this.childIsReady = child;
            if (child && this.displayForSummary) {
                this.seeAnswerSolution();
                this.disableOnChange.next(true);
            } else if (child && this.answersSelected.length) {
                this.checkAnswer();
            }
        });
    }

    ngOnDestroy(): void {
        this.disableOnChange.next();
        this.disableOnChange.complete();
        super.ngOnDestroy();
    }

    protected setContentData(activityAttributes): void {
        this.waitUntilCorrectionFinished = false;
        this.userTry = 0;
        this.referenceActivityGranule = activityAttributes.reference;
        if (this.referenceActivityGranule?.activity_content?.answers
            && this.referenceActivityGranule?.activity_content?.answers[0]?.feedback) {
            this.feedbackEndExo = this.referenceActivityGranule?.activity_content?.answers[0]?.feedback;
        }
        if (this.referenceActivityGranule.config) {
            this.isTwoColumns = this.referenceActivityGranule.config.doubleColumn;
            this.isCaseSensitive = !!this.referenceActivityGranule.config.caseSensitive;
            this.isFormula = this.lessonsConfigurationService.displayLatexKeyboard() && (this.referenceActivityGranule.config.latexKeyboard || !this.referenceActivityGranule.config.suggestedButtons);
            this.isSuggestedButtons = this.referenceActivityGranule.config.suggestedButtons;
        }
        if (activityAttributes.reference.feedback) {
            this.answerFeedback = activityAttributes.reference.feedback;
        }
        this.instruction = this.referenceActivityGranule.instruction;
        this.wording = this.referenceActivityGranule.wording;
        this.instructionAudio = this.referenceActivityGranule.instructionAudio;
        this.wordingAudio = this.referenceActivityGranule.wordingAudio;
        this.fillInBlanksWording = this.referenceActivityGranule.activity_content;
        this.items[0] = this.referenceActivityGranule;
        this.availableAnswers = this.referenceActivityGranule.activity_content.answers.map((answer) => {
            return {
                id: answer.id,
                answer: answer.answer,
                correct_answer: answer.correct_answer,
                state: ItemAnswerStateEnum.pristine,
                fid: answer.fid,
                flashcards: []
            };
        });
        if (!this.isActivityEmbedded) {
            this.loadUserSave();
        }
        if (this.isFormula || this.isSuggestedButtons) {
            super.addExceptionsForButtonsFromActivity([
                {
                    type: 'validate',
                    display: false,
                    options: {
                        RB: {
                            display: {
                                case: 'force',
                                value: false
                            }
                        },
                    }
                }
            ]);
        }
    }

    /**
     * fetch answer in html element or from latex's Keyboard
     * want to use way more simplified 'map' but see todo in function...
     */
    getAnswersFromChild() {
        if (this.childIsReady) {
            if (this.isFormula) {
                return this.child.latexKeyboard.getAllLatex().map((answer) =>
                    ({
                        id: uuidv4(),
                        answer: answer,
                        state: ItemAnswerStateEnum.pristine,
                    })
                );
            }
        }
        return [{
            id: uuidv4(),
            answer: this.child.inputControl?.value || '',
            state: ItemAnswerStateEnum.pristine,
        }]
    }

    protected setAnswer(): void {
        if ((this.userSave?.get('userActivity')?.entitySave?.answers) && !this.displayForSummary) {
            this.answersSelected = this.userSave.get('userActivity').entitySave.answers
                .map((answer) => ({
                id: answer.id,
                answer: answer,
                state: ItemAnswerStateEnum.pristine,
            }));
            this.setUserAnswersToForm();
            if(!this.lessonsService.isTrainerSeeCorrection()) {
                this.activitiesService.userAnswer.next(this.userSave.get('userActivity').entitySave.answers);
                this.checkAnswer();
                this.testAnswer = true;
            }
        }
    }

    private setUserAnswersToForm(): void {
        if (this.isFormula && this.childIsReady) {
            this.childIsReady.latexKeyboard.pushAllLatex(this.answersSelected.map((item) => item.answer));
        } else if (this.isSuggestedButtons) {
            this.elementRef.nativeElement.querySelectorAll('.renderedInputContent')
                .forEach((elem, index) => {
                    elem.innerText = this.answersSelected[index] && this.answersSelected[index].answer || '';
                });
        } else {
            if (this.childIsReady) {
                this.childIsReady.inputControl.setValue(this.answersSelected[0]?.answer || '');
            }
        }
    }

    protected checkAnswer(): void {
        this.answerStatus = this.answersSelected.length ? answerStatusEnum.answered : answerStatusEnum.missing;
        this.answerValidated = true;
        this.disableOnChange.next(false);
        // réponse rentré par l'user, elle ne sont pas encore corrigé (état de la réponse par defaut 'pristrine')
        this.correctAnswerState(this.answersSelected);
        this.setAnswerStatus();

        this.activitiesService.isUserAnswerStatus
            .next({status: this.answerStatus, index: this.activityStepIndex});

        if (this.testAnswer) {
            this.activitiesService.checkAnswers.next({lessonCorrected: true});
        }
        if (this.autoCorrection) {
            this.animateAnswerState();
        }

    }

    private setAnswerStatus(): void {
        const findMissingAnswer = this.answersSelected.some((answer) => answer.state === ItemAnswerStateEnum.missing);
        const findWrongAnswer = this.answersSelected.some((answer) => answer.state === ItemAnswerStateEnum.incorrect);
        if (!findWrongAnswer && !findMissingAnswer) {
            this.answerStatus = answerStatusEnum.correct;
            this.btnValidateState = ItemAnswerStateEnum.currentlyCorrect;
        } else {
            this.btnValidateState = ItemAnswerStateEnum.incorrect;
            if (findWrongAnswer && findMissingAnswer) {
                this.feedback = 'activities.feedback.rb.missing_wrong';
                this.answerStatus = answerStatusEnum.wrong;
            } else {
                if (findWrongAnswer) {
                    this.btnValidateState = ItemAnswerStateEnum.incorrect;
                    this.feedback = 'activities.feedback.rb.wrong';
                    this.answerStatus = answerStatusEnum.wrong;
                }
                if (findMissingAnswer) {
                    this.btnValidateState = ItemAnswerStateEnum.missing;
                    this.feedback = 'activities.feedback.rb.missing';
                    this.answerStatus = answerStatusEnum.missing;
                }
            }
        }
    }

    protected getGrade(): { oldGrade: number, newGrade: number } {
        const oldAnswers: AnswerInterface[] = [];
        if (this.userSave && this.userSave.get('userActivity').entitySave.answers.length) {
            this.correctAnswerState(this.userSave.get('userActivity').entitySave.answers.map((item) => ({
                id: uuidv4(),
                answer: item,
                state: ItemAnswerStateEnum.pristine
            })));
        }
        return {
            oldGrade: this.calculateGrade(oldAnswers),
            newGrade: this.calculateGrade(this.answersSelected)
        };
    }

    /**
     * TODO CODER LE FAIT D'AVOIR PLUSIEURS REPONSES
     * corrige les réponses en affectant un etat cf ItemAnswerState
     */
    correctAnswerState(answerSelectedFromUserSave: (AnswerInterface & {state ?: ItemAnswerStateEnum})[]): void {
        const answerToApplyCorrection = answerSelectedFromUserSave[0] || this.answersSelected[0];
        const answers = this.isSuggestedButtons ? this.availableAnswers.filter((answer) => !!+answer.correct_answer) : this.availableAnswers;
        answers.forEach((answerExpected: AnswerInterface) => {
            // si la réponse est manquante
            if (answerToApplyCorrection?.answer === '') {
                answerToApplyCorrection.state = ItemAnswerStateEnum.missing;
            } else {
                // si réponse existe et que l'on corrige en incluant la sensibilité à la casse, on formate la reponse selon cas.
                if (!this.isCaseSensitive) {
                    answerToApplyCorrection.answer = answerToApplyCorrection.answer.toLowerCase();
                    answerExpected.answer = answerExpected.answer.toLowerCase();
                }
                // si la réponse est correcte
                if (answerToApplyCorrection.answer.replace(/ /g, '') === this.getParsedAnswer(answerExpected.answer)[0].replace(/ /g, '')) {
                    answerToApplyCorrection.state = ItemAnswerStateEnum.currentlyCorrect;
                } else {
                    // si la réponse est incorrecte
                    answerToApplyCorrection.state = ItemAnswerStateEnum.incorrect;
                }
            }
        });
    }

    private calculateGrade(answersEntered: (AnswerInterface & {state?: ItemAnswerStateEnum})[]): number {
        return answersEntered.filter((item) => item.state === ItemAnswerStateEnum.wasCorrect).length / this.availableAnswers.length;
    }

    /**
     * voire la solution de l'exercice.
     * @protected
     */
    protected seeAnswerSolution(): void {
        const correctAnswers = this.isSuggestedButtons ? this.availableAnswers.filter((answer) => !!+answer.correct_answer) : this.availableAnswers;
        this.answersSelected = correctAnswers.map((answer) => ({
                id: answer.id,
                answer: this.getParsedAnswer(answer.answer)[0],
                state: ItemAnswerStateEnum.currentlyCorrect,
                fid: answer.fid,
                flashcards: []
            }));
        this.setUserAnswersToForm();
        this.displaySolution = true;
    }

    protected reset(resetAllSubscribe = false, type = null): Observable<boolean> {
        this.waitUntilCorrectionFinished = false;
        this.btnValidateState = ItemAnswerStateEnum.pristine;
        this.answersSelected = [];
        this.setUserAnswersToForm();
        if (resetAllSubscribe) {
            this.answerFeedback = null;
            if (this.disableOnChange) {
                this.disableOnChange.next(null);
                this.disableOnChange.complete();
            }
            this.disableOnChange = new ReplaySubject<boolean>(1);
        }
        this.resetField();
        if (this.isFormula && this.childIsReady) {
            this.childIsReady.latexKeyboard.pushAllLatex([]);
        }
        this.answerValidated = false;
        this.disableOnChange.next(false);
        return super.reset(resetAllSubscribe, type);
    }

    private resetField(): void {
        const anstxt = this.elementRef.nativeElement.querySelectorAll('.ans-txt');
        const msgicon = this.elementRef.nativeElement.querySelectorAll('.msg-icon');
        const elcolor = this.elementRef.nativeElement.querySelectorAll('.renderedInputContent');
        const wrappers = this.elementRef.nativeElement.querySelectorAll('.latex-wrapper');

        elcolor.forEach((elem, index) => {
            const errorElem = msgicon[index];
            if (errorElem) {
                errorElem.classList.remove('right');
                errorElem.classList.remove('wrong');
                errorElem.classList.remove('validated');
                errorElem.classList.remove('missingResponse');
                errorElem.classList.add('hide');
            }

            if (this.isFormula) {
                /* HACK : L'un des passage pour arriver ici c'est ngOnInit -> initialize -> reset, mais this.child n'est pas encore valorisé
                 *  donc on s'attends à ce que ça plante sur cette ligne.
                 *  Mais comme on est en initialisation "elcolor" est lui aussi vide (tableau vide) donc on passe pas dans cette boucle.
                 */
                wrappers[index].classList.remove('correction');
            } else if (anstxt[index]) {
                elem.innerText = '';
                elem.classList.remove('hide');
                anstxt[index].innerText = '';
                anstxt[index].classList.remove('hide');
            }
        });
    }

    protected reviewAnswer(): void {
        const anstxt = this.elementRef.nativeElement.querySelectorAll('.ans-txt');
        const msgicon = this.elementRef.nativeElement.querySelectorAll('.msg-icon');
        const elcolor = this.elementRef.nativeElement.querySelectorAll('.renderedInputContent');
        const wrappers = this.elementRef.nativeElement.querySelectorAll('.latex-wrapper');
        let solutionElements;

        if (this.isFormula) {
            solutionElements = elcolor;
            this.childIsReady.latexKeyboard.pushAllLatex([]);
        } else {
            solutionElements = anstxt;
        }

        solutionElements.forEach((elem, index) => {
            elem.classList.remove('hide');
            const errorElem = msgicon[index];
            errorElem.classList.remove('right');
            errorElem.classList.remove('wrong');
            errorElem.classList.remove('missingResponse');
            errorElem.classList.add('hide');

            if (this.isFormula) {
                wrappers[index].classList.remove('correction');
            } else {
                elem.innerText = '';
                elcolor[index].innerText = '';
                elcolor[index].classList.remove('hide');
            }
        });

        this.setUserAnswersToForm();
        this.displaySolution = false;
    }

    protected saveAnswer() {
        return of(this.answersSelected.map((item: AnswerInterface) => item.answer));
    }

    protected validate(option?: AnswerInterface): void {
        if (this.autoCorrection && !this.waitUntilCorrectionFinished) {
        this.userTry++;
        this.answersSelected = [{
            id: option ? option && option.id : uuidv4(),
            answer: option ? option && this.getParsedAnswer(option.answer)[0] : this.getAnswersFromChild()[0].answer,
            state: ItemAnswerStateEnum.pristine,
            fid: option ? option && option.fid : null,
            flashcards: []
        }];
        this.setUserAnswersToForm();
        this.checkAnswer();
        }
    }

    public get isDisabledOptions(): boolean {
        // verifie si toutes les bonnes réponses ont été trouvées/selectionnées
        return this.displayForSummary || this.isReplyCorrect();
    }

    public isReplyCorrect(): boolean {
        const answers = this.isSuggestedButtons ? this.availableAnswers.filter((answer) => !!+answer.correct_answer) : this.availableAnswers;
        return (answers.length === this.answersSelected.length
                && !this.answersSelected.some((answer) => answer.state === ItemAnswerStateEnum.pristine ||
                    answer.state === ItemAnswerStateEnum.incorrect ||
                    answer.state === ItemAnswerStateEnum.missing));
    }

    public getParsedAnswer(answer: string): string[] {
        return answer && JSON.parse(answer);
    }

    public isCurrentAnswerState(state): boolean {
        return this.answerStatus === ItemAnswerStateEnum[state];
    }

    private animateAnswerState(): void {
        this.waitUntilCorrectionFinished = true;
        const answerResult: AnswerResultInterface = {
            id: +this.activity.id,
            state: ItemAnswerStateEnum.missing,
            isLast: undefined
        };
        // TODO faire une meilleure animation angular
        setTimeout(() => {
            if (this.isSuggestedButtons) {
                this.answersSelected.filter(a => a.state === ItemAnswerStateEnum.incorrect).forEach(a => a.state = ItemAnswerStateEnum.pristine);
                this.answersSelected.filter(a => a.state === ItemAnswerStateEnum.currentlyCorrect).forEach(a => a.state = ItemAnswerStateEnum.wasCorrect);
            } else {
                this.btnValidateState = this.btnValidateState !== ItemAnswerStateEnum.currentlyCorrect ? ItemAnswerStateEnum.pristine : ItemAnswerStateEnum.wasCorrect;
                this.resetField();
            }
            answerResult.isLast = this.isDisabledOptions; // return true if all answer correct cf isDisabledOptions method
            if (this.answersSelected.length) {
                answerResult.state = this.isDisabledOptions ? ItemAnswerStateEnum.currentlyCorrect : ItemAnswerStateEnum.incorrect; // return true if all field correct cf isDisabledOptions method
            }
            super.manageProgressBarEventToSend(answerResult);
            if ((this.userTry === this.lessonsConfigurationService.numberOfTryBeforeDisplayCorrection() && !this.isReplyCorrect()) || this.isDisabledOptions) {
                const answers: string[] = this.availableAnswers.map((item) => {
                    let answerText = item?.answer ? JSON.parse(item?.answer)[0] : '';
                    if (this.availableAnswers.length > 1) {
                        answerText += ', ';
                    }
                    return answerText;
                });
                const options: FeedbackInterface<any> = {};
                if (!(answerResult.state === ItemAnswerStateEnum.currentlyCorrect || answerResult.state === ItemAnswerStateEnum.wasCorrect) && this.userTry === this.lessonsConfigurationService.numberOfTryBeforeDisplayCorrection() && answers.length) {
                    options.title = 'activities.answer_is_incorrect';
                    this.activitiesService.translatedText('activities.correct_answer_was').subscribe((correct_answer_was) => {
                        options.innerHtmlContent = '<label>' + correct_answer_was + ': ' + answers.join() + '</label><br/>';
                    });
                }
                if (this.feedbackEndExo) {
                    if (options.innerHtmlContent) {
                        options.innerHtmlContent += '<label>' + this.feedbackEndExo + '</label>';
                    } else {
                        options.innerHtmlContent = '<label>' + this.feedbackEndExo + '</label>';
                    }
                }
                if (this.isActivityEmbedded) {
                    super.setFeedBackFromApi(null, answerStatusEnum.correct);
                    this.isActivityEmbeddedDone = true;
                } else {
                    setTimeout(() => {
                        this.doAction('next', ['save'], false, options);
                    }, TIME_DELAY_BEFORE_SAVE);
                }
            } else {
                this.waitUntilCorrectionFinished = false;
            }
        }, TIME_DISPLAYING_CORRECTION);

    }

    public speakStateChanged(evt: {id: string, value: boolean }): void {
        this.ttsChange.next(evt);
    }

    protected getAttempts(): number {
        throw new Error('Method not implemented.');
    }

    /**
     * sert aux css pour afficher l'état d'une reponse
     * pour permettre de retourner simplement le "state" qui sera la également la class css
     */
    public optionState(answer: AnswerInterface): string {
        const answerSelected = this.answersSelected?.find((item: AnswerInterface) => item.id === answer.id);
        if (answerSelected) {
            if (!this.autoCorrection && answerSelected.state === ItemAnswerStateEnum.pristine) {
                return 'selected';
            }
            return answerSelected.state;
        }
        return ItemAnswerStateEnum.pristine;
    }

    public get isActivityDoneMessageCanBeDisplayed() {
        return this.isActivityEmbedded && this.answerValidated && (this.isReplyCorrect() || this.isDisabledOptions);
    }
}
