import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, Injector, NgZone, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {fuseAnimations} from '@fuse/animations';
import {AccountManagementProviderService} from '@modules/account-management';
import {ReCaptchaV3Service} from 'ng-recaptcha';
import {FormProfile} from '@modules/account-management/core/form-profil.class';
import {TralaTranslationLoaderService} from '../../../../trala-translation-loader.service';
import {brand, brandLogoSvg, langs, modulesSettings, captcha, defaultRoute} from '../../../../settings';
import {ModelSchema, Structures} from 'octopus-model';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {ProfileService} from '@modules/account-management/core/profile/profile.service';
import {Observable, Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {localizations, WidgetInstance, WidgetInstanceOptions} from 'friendly-challenge';
import {Field, FieldsOptions} from 'shared/fieldsOptions';
import * as _ from 'lodash-es';
import {LegacyFloatLabelType as FloatLabelType} from '@angular/material/legacy-form-field';
import {map, startWith} from 'rxjs/operators';
import {EventService} from 'shared/event.service';
import {ActivatedRoute} from '@angular/router';
import {
    AccountManagementAuthorizationService
} from '@modules/account-management/core/services/account-management-authorization.service';
import {CaptchaService} from '@modules/account-management/core/services/captcha.service';
import {NewUser} from '@modules/account-management/core/model/new-user.model';
import {StandardInterfaceError} from 'shared/models/octopus-connect/typed-interface-error.interface';
import {FormDataModel} from '@modules/account-management/core/model/form-data.model';

// Attention, le meme settings est utilisé dans profile.service
const settingsAuthStructure: ModelSchema = new ModelSchema({
    displayLoginLogo: Structures.boolean(false),
    urlSSO: Structures.boolean(false),
    floatLabelControl: Structures.string('auto')
});

interface RegisterFormErrors {
    you_are?: ValidationErrors;
    find_us?: ValidationErrors;
    region?: ValidationErrors;
    level?: ValidationErrors;
    levelInput?: ValidationErrors;
    email?: ValidationErrors;
    pseudo?: ValidationErrors;
    lastName?: ValidationErrors;
    firstName?: ValidationErrors;
    contact_email?: ValidationErrors;
    password?: ValidationErrors;
    passwordConfirm?: ValidationErrors;
    terms?: ValidationErrors;
    termsMinor?: ValidationErrors;
    captcha?: ValidationErrors;
}

type UserType = 'adult' | 'minor';
type ValidatorType = (control: AbstractControl) => (ValidationErrors | null);

@Component({
    selector: 'fuse-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
    animations: fuseAnimations
})
export class FuseRegisterComponent implements OnInit, AfterViewInit {
    userInformation: FormProfile;
    translateRegisterParams = {product: 'Marque Blanche', resources: 'google'};
    registerForm: UntypedFormGroup;
    public registerFormErrors: RegisterFormErrors;
    termsAdultNotAccepted = false;
    termsMinorNotAccepted = false;
    userIsAdultOrMinor: null | UserType = null;
    brandLogoSvg = brandLogoSvg;
    private recaptchaV3Service: ReCaptchaV3Service;
    public registering = false;
    public loading = false;
    public loadingDatas = true;

    public brand = brand;
    public captcha = captcha;
    public settingsAuth: { [key: string]: any };
    public formData: FormDataModel = {
        title: 'account-management.email_confirmation',
        content: ['account-management.email_confirmation_first_sentence', 'account-management.email_confirmation_second_sentence'],
        redirection: 'generic.back_to_dashboard',
        redirectionLink: defaultRoute.substring(0, 1) === '/' ? defaultRoute : '/' + defaultRoute
    };
    public requestValidate: boolean;
    public blockAccount = false;
    @ViewChild('widgetFC') widgetFC: ElementRef;
    private fields: Field[];
    private friendlyCaptchaSolution: string;
    private widget: WidgetInstance;
    public showInfoUseAcademicEmail = false;
    public floatLabelControl: FloatLabelType;
    // si le select educationalLevel est caché dans le formulaire d'inscription car l'utilisateur indique qu'il est un 'teacher'
    public isEducationalLevelSelectionHiddenIfUserIsTeacher: boolean;
    public hideTermsMinor = true;

    public options: DataEntity[];
    public filteredOptions: Observable<DataEntity[]>;
    private campaign: string;
    public updateDirective = new Subject<boolean>();
    public captchaOk: boolean; // if we want later to see error msg by default initialise this to false

    constructor(
        private formBuilder: UntypedFormBuilder,
        public accountProvider: AccountManagementProviderService,
        private accountAuthorization: AccountManagementAuthorizationService,
        private translationLoader: TralaTranslationLoaderService,
        private octopusConnect: OctopusConnectService,
        public profileService: ProfileService,
        private translate: TranslateService,
        private injector: Injector,
        private eventService: EventService,
        private route: ActivatedRoute,
        private changeDetectorRef: ChangeDetectorRef,
        private captchaService: CaptchaService,
        private ngZone: NgZone
    ) {
        this.route.queryParams.subscribe((params) => {
            this.campaign = params['mtm_campaign'];
        });
    }

    ngOnInit(): void {
        // to capture event friendly captcha
        window['myFriendlyCapthaCallback'] = (solution: string) => {
            // to update data inside angular zone
            this.ngZone.run(() => {
                this.myFriendlyCapthaCallback(solution);
            });
        };

        if (this.captcha === 'recaptcha') {
            this.recaptchaV3Service = <ReCaptchaV3Service>this.injector.get(ReCaptchaV3Service);
        }

        this.settingsAuth = settingsAuthStructure.filterModel(modulesSettings.authentication);
        this.floatLabelControl = this.settingsAuth.floatLabelControl;
        this.initFields();

        this.userInformation = new FormProfile({}, this.octopusConnect, this.fields);

        this.userInformation.completeLoading.subscribe(() => {
            this.loadingDatas = false;

            if (this.registerForm?.controls['region']) {
                // to manage autocompletion region field
                this.options = this.userInformation.region_field as DataEntity[];
                this.filteredOptions = this.registerForm.controls['region'].valueChanges.pipe(
                    startWith(''),
                    map((value: string) => this._filter(value || '')),
                );
            }
        });

        const defaultLang: string[] = langs.map((lang) => lang.id);
        this.translationLoader.loadTranslations(...defaultLang);

        this.registerFormErrors = {
            you_are: {},
            find_us: {},
            region: {},
            level: {},
            levelInput: {},
            email: {},
            pseudo: {},
            lastName: {},
            firstName: {},
            contact_email: {},
            password: {},
            passwordConfirm: {},
            terms: {},
            captcha: {}
        };

        if (this.accountProvider.settings && this.accountProvider.settings.passwordPolicy) {
            this.registerFormErrors['passwordPolicy'] = {};
        }

        this.userIsAdultOrMinor = this.accountProvider.settings.userIsAdultOrMinorOption ? null : 'adult';

        this.requestValidate = false;
        this.generateRegisterForm();
        this.subscribeOnFormChanges();

        if (!this.accountProvider.settings.displayDialogMinorAdult) {
            this.selectMinorOrAdult('adult');
        }
    }

    /**
     * fired when captcah is solved
     * @param solution
     */
    public myFriendlyCapthaCallback(solution: string) {
        this.friendlyCaptchaSolution = solution;
        this.captchaOk = true;
    }

    ngAfterViewInit(): void {
        if (this.captcha === 'friendlyCaptcha') {
            const doneCallback = (solution: string): void => {
                this.friendlyCaptchaSolution = solution;
            };

            const errorCallback = (error): void => {
                console.log('There was an error when trying to solve the Captcha.');
                console.log(error);
            };
            const lang = this.translate.currentLang as keyof typeof localizations;
            const element = this.widgetFC.nativeElement;
            const options: Partial<WidgetInstanceOptions> = {
                startMode: 'none',
                doneCallback: doneCallback,
                errorCallback: errorCallback,
                language: lang
            };

            this.widget = new WidgetInstance(element, options);

            // this makes the widget fetch a puzzle and start solving it.
            setTimeout(() => {
                // without timeout the puzzle solve but we don't see he is doing it we have button without interaction possible
                this.widget.start();
            }, 2000);
        }
    }

    private getValidatorsIfDisplayed(name: string, customValidators: ValidatorFn[]): ValidatorFn[] {
        return this.displayField(name) && !!customValidators
            ? customValidators.filter(v => !!v)
            : [];
    }

    public getUserLanguage(): string {
        return this.translate.currentLang;
    }

    private onRegisterFormValueChanged(field: string, control: UntypedFormControl): void {
        this.registerFormErrors[field] = {};

        if (!this.registerForm.get('terms').errors) {
            this.termsAdultNotAccepted = false;
        }

        if (!this.registerForm.get('termsMinor')?.errors) {
            this.termsMinorNotAccepted = false;
        }

        if (control && control.dirty && !control.valid) {
            this.registerFormErrors[field] = control.errors;
        }

        if (field === 'pseudo' && !this.validateField(control.value)) {
            control.setErrors({invalid: true});
            if (control.value !== '') {
                this.registerFormErrors.pseudo.errorTypo = true;
            }
        } else {
            if (field === 'pseudo' ||
                (field !== 'pseudo'
                    && !(this.registerFormErrors?.pseudo?.customErrors
                    && this.registerFormErrors?.pseudo?.customErrors?.length > 0
                    && this.registerFormErrors?.pseudo?.customErrors[0] === 'account-management.username_taken')
                )
            ) {
                this.registerFormErrors.pseudo = {};
                if (this.registerForm.get('pseudo')?.errors) {
                    this.registerForm.get('pseudo').setErrors(null);
                }
            } else {
                // nothing account already taken error must stay even on change of other fields
            }
        }

        if (field === 'lastName' && !this.validateField(control.value)) {
            control.setErrors({invalid: true});
            if (control.value !== '') {
                this.registerFormErrors.lastName.errorTypo = true;
            }
        } else {
            this.registerFormErrors.lastName = {};
            if (this.registerForm.get('lastName')?.errors) {
                this.registerForm.get('lastName').setErrors(null);
            }
        }

        if (field === 'firstName' && !this.validateField(control.value)) {
            control.setErrors({invalid: true});
            if (control.value !== '') {
                this.registerFormErrors.firstName.errorTypo = true;
            }
        } else {
            this.registerFormErrors.firstName = {};
            if (this.registerForm.get('firstName')?.errors) {
                this.registerForm.get('firstName').setErrors(null);
            }

        }

        if (field === 'you_are') {
            if (control.value === 'teacher' || control.value === 'parent') {
                this.showInfoUseAcademicEmail = this.accountProvider.settings?.emailIncludeText !== ''
                    && !this.registerForm.value.email.includes(this.accountProvider.settings.emailIncludeText);
                if (this.profileService?.settings?.isEducationalLevelSelectionHiddenIfUserIsTeacher) {
                    this.registerForm?.get('level')?.markAsTouched();
                    this.registerForm?.get('level')?.markAsDirty();
                    this.registerForm?.get('level')?.setValidators(null);
                    this.registerForm?.get('level')?.setErrors(null, {emitEvent: false});
                    this.registerForm?.get('level')?.setValue('', {emitEvent: true});

                    this.registerForm?.get('termsMinor')?.markAsTouched();
                    this.registerForm?.get('termsMinor')?.markAsDirty();
                    this.registerForm?.get('termsMinor')?.setValidators(null);
                    this.registerForm?.get('termsMinor')?.setErrors(null, {emitEvent: false});
                    this.registerForm?.get('termsMinor')?.setValue('', {emitEvent: true});

                    this.registerFormErrors.level = {};
                    this.registerFormErrors.termsMinor = {};
                    this.isEducationalLevelSelectionHiddenIfUserIsTeacher = true;
                    this.hideTermsMinor = true;
                }
            } else {
                this.registerForm?.get('level')?.setValidators(this.getRequiredValidatorIfNeeded(this.getFieldByLabel('level')));
                this.isEducationalLevelSelectionHiddenIfUserIsTeacher = false;
            }
        }

        if (field === 'level') {
            if (this.profileService?.settings?.isEducationalLevelSelectionHiddenIfUserIsTeacher
                && (!this.userInformation.level_field.filter((term: DataEntity) => (term.id === control.value)).length
                    || !(this.userInformation.level_field.filter((term: DataEntity) => (term.id === control.value))[0] as DataEntity).get('isMinor'))) {
                this.registerForm?.get('termsMinor')?.markAsTouched();
                this.registerForm?.get('termsMinor')?.markAsDirty();
                this.registerForm?.get('termsMinor')?.setValidators(null);
                this.registerForm?.get('termsMinor')?.setErrors(null, {emitEvent: false});
                this.registerForm?.get('termsMinor')?.setValue('', {emitEvent: true});

                this.registerFormErrors.termsMinor = {};
                this.hideTermsMinor = true;
            } else {
                this.registerForm?.get('termsMinor').setValidators(this.getRequiredValidatorIfNeeded(this.getFieldByLabel('level')));
                this.hideTermsMinor = false;
            }
            // update view because directive adding tabindex dynamicly must be updated with a new field who appear after click on mat-select
            this.changeDetectorRef.detectChanges();
            this.updateDirective.next(true);
        }

        if (field === 'region') {
            // by default it's false it's isSelectedRegion() who is call after who pass it to true if it s
            // this.almostOneRegionSelected = false;
            this.registerForm.get('region').setErrors({regionNotSelected: true});
        }
    }

    public newRegister(): void {
        this.registering = true;

        if (this.registerForm.get('terms')?.errors) {
            this.termsAdultNotAccepted = true;
        }

        if (this.registerForm.get('termsMinor')?.errors) {
            this.termsMinorNotAccepted = true;
        }

        if (!this.registerForm.invalid) {
            const newUser: NewUser = {
                password: this.registerForm.value.password,
                newsletter: this.registerForm.value.newsletter,
                you_are: this.registerForm.value.you_are,
                language: this.getUserLanguage(),
                lastName: this.registerForm.value.lastName,
                firstName: this.registerForm.value.firstName,
                find_us: this.registerForm.value.find_us,
                level: this.displayField('levelInput') ? this.registerForm.value.levelInput : this.registerForm.value.level,
                region: this.registerForm.value.region,
                institution: this.registerForm.value.institution,
            };

            if (this.displayField('label')) {
                newUser.label = this.registerForm.value.pseudo;
            }

            if (this.campaign) {
                newUser.campaign = this.campaign;
            }

            if (this.displayField('lastName')) {
                newUser.lastName = this.registerForm.value.lastName;
            }


            if (this.displayField('firstName')) {
                newUser.firstName = this.registerForm.value.firstName;
                if (this.settings.concatUsernameWithFirstNameLastName) { // TODO default pseudo
                    newUser.label = newUser.firstName + ' ' + newUser.lastName;
                }
            }

            if (this.displayField('email')) {
                if (this.isRequired('email')) {
                    newUser.email = this.registerForm.value.email;
                } else {
                    if (this.registerForm.value.email) {
                        newUser.email = this.registerForm.value.email;
                    }
                    newUser.optionalEmail = true;
                }
            }

            if (this.displayField('contact_email')) {
                newUser.contact_email = this.registerForm.value.contact_email;
            }

            switch (this.captcha) {
                case 'recaptcha':
                    this.captchaService.handleRecaptcha(newUser, (captchaOk, newUser) => {
                        this.updateCaptchaStateAndSend(captchaOk, newUser);
                    });
                    break;
                case 'friendlyCaptcha':
                    this.captchaService.handleFriendlyCaptcha(newUser, this.friendlyCaptchaSolution, this.widget, (captchaOk, newUser) => {
                        this.updateCaptchaStateAndSend(captchaOk, newUser);
                    });
                    break;
                default:
                    console.log('no captcha in setting');
                    this.registering = false;
            }

        }
    }

    private updateCaptchaStateAndSend(captchaOk: boolean, newUser: NewUser): void {
        this.captchaOk = captchaOk;
        if (captchaOk) {
            this.sendRegistration(newUser);
        } else {
            this.widget.reset();
        }
        this.registering = false;
    }

    private sendRegistration(newUser: NewUser): void {
        this.loading = true;
        this.accountProvider.createUser(newUser, (data) => this.getFormConfirmation(data), (err) => this.handleRegistrationError(err));
    }

    public selectMinorOrAdult(mode: UserType): void {
        this.userIsAdultOrMinor = mode;

        if (this.displayField('label') === false) {
            delete this.registerFormErrors.pseudo;
        } else {
            this.registerFormErrors.pseudo = {};
        }

        if (this.displayField('firstName') === false) {
            delete this.registerFormErrors.firstName;
        } else {
            this.registerFormErrors.firstName = {};
        }

        if (this.displayField('contact_email') === false) {
            delete this.registerFormErrors.contact_email;
        } else {
            this.registerFormErrors.contact_email = {};
        }

        if (this.displayField('email') === false) {
            delete this.registerFormErrors.email;
        } else {
            this.registerFormErrors.email = {};
        }

        if (this.userIsAdultOrMinor === 'minor') {
            const getValidatorsOf = (label: string, ...customsValidators: ValidatorType[]) => {
                return this.getValidatorsIfDisplayed(label, [this.getRequiredValidatorIfNeeded(this.getFieldByLabel(label)), ...customsValidators]);
            };

            this.registerForm = this.formBuilder.group({
                email: ['', getValidatorsOf('email', Validators.email)],
                contact_email: ['', getValidatorsOf('contact_email', Validators.email)],
                pseudo: ['', getValidatorsOf('label', Validators.maxLength(60))],
                lastName: ['', getValidatorsOf('lastName', Validators.maxLength(60))],
                firstName: ['', getValidatorsOf('firstName', Validators.maxLength(60))],
                you_are: ['', getValidatorsOf('you_are')],
                find_us: ['', getValidatorsOf('find_us')],
                region: ['', getValidatorsOf('region')],
                level: ['', getValidatorsOf('level')],
                levelInput: ['', getValidatorsOf('levelInput')],
                password: ['', getValidatorsOf('password', this.confirmPassword)],
                passwordConfirm: ['', getValidatorsOf('password', this.confirmPassword)],
                newsletter: [false],
                terms: [false, Validators.requiredTrue],
                termsMinor: [false, Validators.requiredTrue],
                institution: ['', getValidatorsOf('institution')],
                captcha: ['', Validators.required]
            });

            this.subscribeOnFormChanges();
        }

    }

    /**
     * only letter without accent , number and unserscore withour space
     * @param field : form field value to test
     */
    private validateField(field: string): boolean {
        const re = /^\w+(\s\w+)*$/; // only letter without accent, number and underscore no space possible except one beetween word
        return re.test(field.toLowerCase());
    }

    private getFormConfirmation(data: DataEntity): void {
        this.eventService.trackEvent(
            'register',
            'User register',
            'register',
        );
        if (!data?.get('blocked')) {
            this.requestValidate = this.accountAuthorization.settings.validateEmailStrategyActivated;
        } else {
            this.blockAccount = true;
        }
    }

    public displayField(name: string): boolean {
        return !!this.getFieldByLabel(name);
    }

    public confirmPasswordPolicy(control: AbstractControl): ValidationErrors {
        if (!control || !control.parent) {
            return;
        }

        const password = control.parent.get('password');

        if (password && password.value) {
            if (this.accountProvider.settings && !this.accountProvider.settings.passwordPolicy) {
                return;
            }
            if (!this.accountProvider.settings.passwordPolicy.regex.test(password.value)) {
                return {
                    passwordPolicyError: true
                };
            }
        }
        return;
    }

    public flat<T>(...errors: T[][]): T[] {
        return _.flatten(errors);
    }

    public isRequired(label: string): boolean {
        let result = false;
        try {
            result = FieldsOptions.isRequired(this.getFieldByLabel(label));
        } catch (e) {
            // on avale l'erreur
        }

        return result;
    }

    private getFieldByLabel(label: string): Field {
        return this.fields.find(f => f.label === label);
    }

    private handleRegistrationError(error: StandardInterfaceError): void {
        this.loading = false;

        const handleFieldsError = (field: string, messages: string[]) => {
            const localField = this.mapField(field);

            if (Object.keys(this.registerFormErrors).indexOf(localField) > -1) {
                this.registerFormErrors[localField].customErrors = messages;
                const control = this.registerForm.get(localField);
                control.setErrors({invalid: true});
            } else {
                console.error(error);
            }
        };

        if (error.code !== 401) {
            console.error(error);

            if (error.code === 400 && error.data.response) {
                if (error.data.response.title === 'username exists in database') {
                    handleFieldsError('label', ['account-management.username_taken']);
                }

                if (error.data.response.title === 'email exists in database') {
                    handleFieldsError('email', ['account-management.mail_taken']);
                }
            }
        } else if (error.data.response.hasOwnProperty('errors')) {
            Object.keys(error.data.response.errors).forEach((key) => {
                handleFieldsError(key, error.data.response.errors[key]);
            });
        }
        this.captchaOk = false;
        this.widget.reset();

    }

    // TODO refact :  on peut gérer quasiment toute les lignes avec un "default" case et écrire mutualisé l'appel au addControl.
    private generateRegisterForm(): void {
        this.registerForm = this.formBuilder.group({
            terms: [false, Validators.requiredTrue],
        });

        // add captcha
        this.registerForm.addControl('captcha', new UntypedFormControl('', this.getValidatorsIfDisplayed('captcha', [Validators.required])));

        this.fields.forEach((field, _index, array) => {
            switch (field.label) {
                case 'email': {
                    // eslint-disable-next-line max-len
                    this.registerForm.addControl('email', new UntypedFormControl('', this.getValidatorsIfDisplayed('email', [this.getRequiredValidatorIfNeeded(field), Validators.email, this.getBackendValidatorIfNeeded(field)])));
                    break;
                }
                case 'find_us': {
                    this.registerForm.addControl('find_us', new UntypedFormControl('', this.getValidatorsIfDisplayed('find_us', [this.getRequiredValidatorIfNeeded(field)])));
                    break;
                }
                case 'termsMinor': {
                    this.registerForm.addControl('termsMinor', new UntypedFormControl(false));
                    break;
                }
                case 'region': {
                    this.registerForm.addControl('region', new UntypedFormControl('', this.getValidatorsIfDisplayed('region', [this.getRequiredValidatorIfNeeded(field)])));
                    break;
                }
                case 'level': {
                    this.registerForm.addControl('level', new UntypedFormControl('', this.getValidatorsIfDisplayed('level', [this.getRequiredValidatorIfNeeded(field)])));
                    break;
                }
                case 'levelInput': {
                    this.registerForm.addControl('levelInput', new UntypedFormControl('', this.getValidatorsIfDisplayed('levelInput', [this.getRequiredValidatorIfNeeded(field)])));
                    break;
                }
                case 'newsletter': {
                    this.registerForm.addControl('newsletter', new UntypedFormControl(false));
                    break;
                }
                case 'password': {
                    const validators = [this.getRequiredValidatorIfNeeded(field)];
                    if (array.some(f => f.label === 'passwordConfirm')) {
                        validators.push((control) => this.confirmPassword(control));
                    }
                    if (this.accountProvider.settings && this.accountProvider.settings.passwordPolicy) {
                        validators.push((c: AbstractControl) => this.confirmPasswordPolicy(c));
                    }
                    this.registerForm.addControl('password', new UntypedFormControl('', this.getValidatorsIfDisplayed('password', validators)));
                    break;
                }
                case 'passwordConfirm': {
                    const validators = [this.getRequiredValidatorIfNeeded(field), (control: AbstractControl) => this.confirmPassword(control)];
                    if (this.accountProvider.settings && this.accountProvider.settings.passwordPolicy) {
                        validators.push((c: AbstractControl) => this.confirmPasswordPolicy(c));
                    }
                    this.registerForm.addControl('passwordConfirm', new UntypedFormControl('', this.getValidatorsIfDisplayed('passwordConfirm', validators)));
                    break;
                }
                case 'label': {
                    // eslint-disable-next-line max-len
                    this.registerForm.addControl('pseudo', new UntypedFormControl('', this.getValidatorsIfDisplayed('label', [this.getRequiredValidatorIfNeeded(field), Validators.maxLength(60), this.getBackendValidatorIfNeeded(field)])));
                    break;
                }
                case 'lastName': {
                    this.registerForm.addControl('lastName', new UntypedFormControl(''));
                    break;
                }
                case 'firstName': {
                    this.registerForm.addControl('firstName', new UntypedFormControl(''));
                    break;
                }
                case 'institution': {
                    this.registerForm.addControl('institution', new UntypedFormControl(''));
                    break;
                }
                case 'you_are': {
                    this.registerForm.addControl('you_are', new UntypedFormControl('', this.getValidatorsIfDisplayed('you_are', [this.getRequiredValidatorIfNeeded(field)])));
                    break;
                }
            }
        });
    }

    private mapField(backendField: string): string {
        return backendField === 'label' ? 'pseudo' : backendField;
    };

    private subscribeOnFormChanges(): void {
        Object.keys(this.registerForm.controls).forEach(key => {
            const control = <UntypedFormControl>this.registerForm.controls[key];
            control.valueChanges.subscribe(() => {
                this.onRegisterFormValueChanged(key, control);
            });
        });
    }

    private initFields(): void {
        let fields = this.accountProvider.settings.fields[this.userIsAdultOrMinor];
        if (fields === null || fields === undefined) {
            fields = this.accountProvider.settings.fields['default'] || [];
        }

        this.fields = fields.map((field: string) => new Field(field));
    }

    private getRequiredValidatorIfNeeded(field: Field): ValidatorType {
        return this.isRequired(field.label)
            ? Validators.required
            : undefined;
    }

    private getBackendValidatorIfNeeded(field: Field): ValidatorType {
        const localField = this.mapField(field.label);
        return (control: AbstractControl) => {
            return this.registerFormErrors[localField]?.customErrors?.length > 0 ? {invalid: true} : null;
        };
    }

    private confirmPassword(control: AbstractControl): ValidationErrors {
        if (this.registerFormErrors) {
            this.registerFormErrors.passwordConfirm.passwordsNotMatch = false;
            if (!control || !control.parent) {
                return;
            }

            const password = control.parent.get('password');
            const passwordConfirm = control.parent.get('passwordConfirm');

            if (!password || !passwordConfirm) {
                return;
            }

            if (passwordConfirm.value === '') {
                return;
            }

            if (password.value !== passwordConfirm.value) {
                if (control === password) {
                    passwordConfirm.setErrors({passwordsNotMatch: true});
                    this.registerFormErrors.passwordConfirm.passwordsNotMatch = true;
                } else {
                    return {
                        passwordsNotMatch: true
                    };
                }
            }
        }
    }

    /**
     * fired when email value change
     * show or not info in regard of settings momenclature email and user type
     * @param event : keyup event
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public onChangeEmail(event: KeyboardEvent): void {
        if (this.accountProvider.settings.emailIncludeText === '' || !this.accountProvider.settings.emailIncludeText) {
            return;
        }
        this.showInfoUseAcademicEmail = !this.registerForm.value.email.includes(this.accountProvider.settings.emailIncludeText) && this.registerForm.value.you_are === 'teacher';
    }

    public get settings(): { [key: string]: any } {
        return this.accountProvider.settings;
    }

    public get authenticationSettings(): { [key: string]: any } {
        return this.accountProvider.authenticationSettings;
    }

    public get hideNewsletter(): boolean {
        return !this.isRequired('email') && (!this.registerForm.value.email || this.registerFormErrors.email.email || this.registerFormErrors.email.customErrors);
    }

    public levelInputClass(): string {
        return this.registerForm?.get('you_are')?.value ? 'level-input' : 'level-input-hide';
    }

    /**
     * check if element exist in the list if not suppress it
     * @param event
     */
    public inputControl(event: FocusEvent): void {
        setTimeout(() => {
            const isValueTrue = this.options.filter(o =>
                o.attributes.label === (event.target as HTMLInputElement).value);
            if (isValueTrue.length === 0) {
                // this.almostOneRegionSelected = false;
                this.registerForm.get('region').setValue(null);
            }
        }, 300);
    }

    /**
     * return the label in regard of the id
     * @param id : region id
     */
    public displayFn(id: string | number): string {
        if (!id) {
            return '';
        }

        const index = this.options.findIndex(o => +o.id === +id);
        return this.options[index].attributes.label;
    }

    /**
     * get the current selected element in the list
     * @param id
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public isSelectedRegion(id: string): void {
        this.registerForm.get('region').setErrors(null, {emitEvent: false});
    }

    /**
     * filter for autocompletion region field
     * @param value
     * @private
     */
    private _filter(value: string): DataEntity[] {
        const filterValue = value.toLowerCase();
        return this.options.filter(option => option.attributes.label.toLowerCase().includes(filterValue));
    }

    public hasRedirectUrl(): boolean {
        return !!this.settings.redirectSignup.url;
    }
}



