import {HttpClient} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {CommunicationCenterService} from '@modules/communication-center';
import {merge} from 'lodash-es';
import {OctopusConnectService} from 'octopus-connect';
import {combineLatest, Observable, of, ReplaySubject} from 'rxjs';
import {map, mergeMap, take} from 'rxjs/operators';
import {GranuleMediaFormatEntity} from 'shared/media/granule-media-format.entity';
import {MediaGranuleEntity} from 'shared/media/media-granule.entity';
import {FileData, FileMime, GenericBackResponse} from 'shared/models';
import {GranuleMetadataEntity} from 'shared/models/granule';
import {defaultApiURL} from '../../settings';

export type OnlyImageFileMimes = 'image/jpeg' | 'image/png' | 'image/webp';

const FIVE_MEGABYTES = 5_000_000 as const;
const TEN_MEGABYTES = 10_000_000 as const;
const FIFTY_MEGABYTES = 50_000_000 as const;

const MEDIA_API_URL = defaultApiURL + 'api/file-upload';
const GRANULE_ENDPOINT = 'granule';
const METADATA_ENDPOINT = 'metadatas';
const BACKEND_MEDIA_FORMAT_ENDPOINT = 'granule-format';

const MEDIA_VALIDATION_RULES = {
    audio: {
        mimeTypes: [FileMime['audio/mpeg']],
        maxSize: TEN_MEGABYTES
    },
    image: {
        mimeTypes: [FileMime['image/jpeg'], FileMime['image/png'], FileMime['image/webp']],
        maxSize: FIVE_MEGABYTES
    },
    video: {
        mimeTypes: [FileMime['video/mp4']],
        maxSize: FIFTY_MEGABYTES
    }
} as const;

export const ERROR_MESSAGES = {
    invalidFileType: 'Invalid file type',
    fileTooBig: 'File is too big'
} as const;

@Injectable({
    providedIn: 'root'
})
export class MediaService {

    private http = inject(HttpClient);
    private octopusConnect = inject(OctopusConnectService);
    private communicationCenter = inject(CommunicationCenterService);

    private userAccessToken$ = this.communicationCenter.getRoom('authentication').getSubject<string>('userAccessToken');
    private formats$ = new ReplaySubject<GranuleMediaFormatEntity[]>(1);

    constructor() {
        this.octopusConnect.loadCollection(BACKEND_MEDIA_FORMAT_ENDPOINT).pipe(
            map(collection => collection.entities as GranuleMediaFormatEntity[]),
            take(1)
        ).subscribe((formats) => this.formats$.next(formats));
    }

    public getFileById<T extends FileMime = FileMime>(id: string) {

        // On ne passe pas par octopusConnect car on veut que le résultat ait me meme format que le POST
        return this.userAccessToken$.pipe(
            take(1),
            mergeMap(token => {
                const headers = {'access-token': token};
                return this.http.get<GenericBackResponse<FileData<T>>>(`${MEDIA_API_URL}/${id}`, {headers});
            }),
            map(response => response.data[0])
        );
    }

    public uploadImage(file: File) {
        // En utilisant of() la montée d'erreur est gérée par le catchError du service appelant
        return of({}).pipe(
            mergeMap(() => {

                // Verify file type
                const allowedMimeTypes = MEDIA_VALIDATION_RULES.image.mimeTypes.slice() as string[];
                if (!allowedMimeTypes.includes(file.type)) {
                    throw new Error(ERROR_MESSAGES.invalidFileType);
                }


                // Verify file size
                if (file.size > MEDIA_VALIDATION_RULES.image.maxSize) {
                    throw new Error(ERROR_MESSAGES.fileTooBig);
                }

                return this.postFile<OnlyImageFileMimes>(file);
            })
        );
    }

    public uploadVideo(file: File) {
        // En utilisant of() la montée d'erreur est gérée par le catchError du service appelant
        return of({}).pipe(
            mergeMap(() => {

                // Verify file type
                const allowedMimeTypes = MEDIA_VALIDATION_RULES.video.mimeTypes.slice() as string[];
                if (!allowedMimeTypes.includes(file.type)) {
                    throw new Error(ERROR_MESSAGES.invalidFileType);
                }

                // Verify file size
                if (file.size > MEDIA_VALIDATION_RULES.video.maxSize) {
                    throw new Error(ERROR_MESSAGES.fileTooBig);
                }

                return this.postFile<'video/mp4'>(file);
            })
        );
    }

    public uploadAudio(file: File) {
        // En utilisant of() la montée d'erreur est gérée par le catchError du service appelant
        return of({}).pipe(
            mergeMap(() => {

                // Verify file type
                const allowedMimeTypes = MEDIA_VALIDATION_RULES.audio.mimeTypes.slice() as string[];
                if (!allowedMimeTypes.includes(file.type)) {
                    throw new Error(ERROR_MESSAGES.invalidFileType);
                }

                // Verify file size
                if (file.size > MEDIA_VALIDATION_RULES.video.maxSize) {
                    throw new Error(ERROR_MESSAGES.fileTooBig);
                }

                return this.postFile<'audio/mpeg'>(file);
            })
        );
    }

    public deleteMedia(id: string) {
        return this.userAccessToken$.pipe(
            take(1),
            mergeMap(token => {
                const headers = {'access-token': token};
                return this.http.delete(`${MEDIA_API_URL}/${id}`, {headers});
            })
        );
    }

    public getMimeTypes(mediaType: string extends keyof typeof MEDIA_VALIDATION_RULES ? never : keyof typeof MEDIA_VALIDATION_RULES) {
        return MEDIA_VALIDATION_RULES[mediaType].mimeTypes;
    }

    // TODO : Le Corpus devrait utiliser cette méthode
    public createGranuleFromMedia<T extends FileMime>(media: FileData<T> | any, type: string, metadatas = {}) {
        const defaultMetadatas = {title: media?.label || '', description: ''};
        const postMetadatas$ = (metadata) => this.octopusConnect
            .createEntity(METADATA_ENDPOINT, metadata)
            .pipe(take(1)) as Observable<GranuleMetadataEntity>;

        const loadMediaFormat$ = (mediaType: string) => this.formats$.pipe(
            take(1),
            map(formats => {
                const format = formats.find(format => format.get('label') === mediaType);

                if (!format) {
                    return formats.find(format => format.get('label') === 'media');
                }

                return format;
            }));

        const postGranule$ = (metadataId, formatId) => this.octopusConnect
            .createEntity(GRANULE_ENDPOINT, {
                metadatas: metadataId,
                format: formatId,
                reference: media?.id
            })
            .pipe(take(1)) as Observable<MediaGranuleEntity>;


        const rawMetadatas = merge({}, defaultMetadatas, metadatas);

        const toCorrectType = {
            'vidéo': 'video',
            'texte': 'text',
        }

        const backendType = type in toCorrectType ? toCorrectType[type] : type;

        return combineLatest([postMetadatas$(rawMetadatas), loadMediaFormat$(backendType)]).pipe(
            mergeMap(([metadata, format]) => postGranule$(metadata.id, format.id))
        );
    }

    private postFile<T extends FileMime>(file: File) {
        const formData = new FormData();
        formData.append('file', file);

        return this.userAccessToken$.pipe(
            take(1),
            mergeMap(token => {
                const headers = {'access-token': token};
                return this.http.post<GenericBackResponse<FileData<typeof FileMime[T]>[]>>(MEDIA_API_URL, formData, {headers});
            }),
            map(response => response.data[0][0])
        );
    }
}
