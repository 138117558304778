import {FlashcardAttribute} from 'shared/flashcard';
import {MergeTypes} from 'shared/merge';
import {TypedDataEntityInterface} from 'shared/models/octopus-connect';

/**
 * format d'une réponse pour les activités
 * Todo: faire en sorte que chaque activité ait sa propre interface pour faciliter la comprehension exemple: QcmItemAnswerInterface extends ItemAnswerInterface {}
 */
export interface AnswerInterface {
    // state: ItemAnswerStateEnum;
    id: string;
    answer: string;
    // for all activity with user-save's endpoint qcm-save
    correct_answer?: boolean;
    select?: boolean;
    image?: string;
    // id du media associé
    fid: string;
    // optionnal help to show on bad answers
    feedback?: string;
    // optionnal audio file join to answer
    audio?: IMediaInterface;
    flashcards: ({id: string} & FlashcardAttribute)[];
}

/** C'est une interface utilisée pour les textmaching par exemple */
export interface AnswerAppInterface {
    id: string;
    source: string;
    target: string;
    media: string;
}

export type UpdateAnswerInterface = Partial<
    MergeTypes<[
        Omit<AnswerInterface, 'id' | 'flashcards' | 'audio'>,
        {flashcards: (string | number)[]},
        {audio: string|number},
        {image: string|number},
    ]>
>;
export type CreateAnswerAppInterface = Partial<Omit<AnswerAppInterface, 'id'>>;

export type AnswerEntity = TypedDataEntityInterface<AnswerInterface>
export type AnswerAppEntity = TypedDataEntityInterface<AnswerAppInterface>

export interface IMediaInterface {
    // id of media
    id: string;
    // url of content
    uri: string;
    // alternative value for accessibility
    alt: string;
}
