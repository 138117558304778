import {Injectable} from '@angular/core';
import {CollectionOptionsInterface, DataEntity, OctopusConnectService, PaginatedCollection} from 'octopus-connect';
import {map, take} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DictionaryService {
    private dataPaginated: PaginatedCollection;
    private filterOptions: CollectionOptionsInterface = {filter: {}, page: 1, range: 20};

    constructor(private octopusConnectService: OctopusConnectService) {
    }

    public get filteredLetter(): string {
        return this.filterOptions.filter['letter'] || 'A';
    }

    /**
     * return flashcard formated
     */
    public loadFlashCard(page: number = 1) {
        return this.loadData(page).pipe(
            map((data: DataEntity[]) => data.map(d => ({
                    id: d.get('id'),
                    title: d.get('title'),
                    image: d.get('image'),
                    audio: d.get('audio'),
                    data: d.get('data')
                }))
            ));
    }

    /**
     * return dictionary Card formated
     */
    public loadDictionaryCard(page = 1) {
        return this.loadData(page).pipe(
            map((data: DataEntity[]) => data.map(d => ({
                    id: d.id.toString(),
                    title: d.get('title'),
                    image: d.get('image'),
                    audio: d.get('audio'),
                    data: d.get('data')
                }))
            ));
    }

    public setFilterOptions(options: Partial<CollectionOptionsInterface>): void {
        this.filterOptions = {...this.filterOptions, ...options};
    }

    /**
     * get flashCard
     */
    private loadData(page = 1): Observable<DataEntity[]> {
        this.filterOptions.page = page;
        this.dataPaginated = this.octopusConnectService.paginatedLoadCollection('dictionary_search', this.filterOptions);
        return this.dataPaginated.collectionObservable
            .pipe(
                take(1),
                map(collection => collection.entities)
            );
    }

    public getAudio(text: string ): Observable<string> {
        return this.octopusConnectService.loadEntity('acapela', text).pipe(
            map(entity => {
                return entity.get('audio');})
        );
    }
}
